@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

$main-font: "Open Sans";
$title-font: "Bebas";
$pale-blue: #9dc4ef;
$blue: #4a83dc;
$light-blue: #66c6e4;
$dark-blue: #0d59ab;
$gradient-1: linear-gradient(120.98deg, var(--blue) 48.77%, #3266b8 85.89%);
$gradient-2: linear-gradient(120.98deg,
        rgba(72, 216, 104, 0.8) 48.77%,
        rgba(120, 216, 72, 0.8) 85.89%);
$glass: linear-gradient(92.32deg,
        rgba(255, 255, 255, 0) 16.31%,
        rgba(245, 251, 255, 0.3) 97.01%),
    rgba(255, 255, 255, 0.3);
$dark-green: #005a47;
$green: #48d868;
$pale-green: #94e5a6;
$aqua: rgba(67, 185, 198, 0.26);
$rose: #ff5630;

$white: #ffffff;
$extrapale-gray: #ecf4fc;
$pale-gray: #d9d9d9;
$light-gray: #c1c1c1;
$gray: #8f8f8f;
$black: #1b1b1c;
$total-black: #000;
$transition: 300ms ease-in-out;
$radius: 0.67em;

$bp-sm: 575.98px;
$bp-md: 767.98px;
$bp-lg: 991.98px;
$bp-xl: 1199.98px;
$bp-xxl: 1399.98px;


:root {
    --h-height: 85px;
    --f-height: 80px;

    --s-menu-width: 76px;
    --l-menu-width: 267px;

    @media (max-width: $bp-xxl) {
        --h-height: 70px;
    }

    @media (max-width: 767px) {
        --h-height: 70px;
    }

    @media (max-width: 575px) {
        --h-height: 55px;
        --f-height: 64px;
    }
}

:root[data-theme='light'] {
    --body-bg: #fff;
    --second-bg: #fff;
    --footer-bg: #1B1B1C;
    --font-color: #191F2D;
    --switcher: #F4F4F4;
    --extrapale-blue: #E5F2FF;
    --extrapale-line: #E5F2FF;
    --pale-blue: #9dc4ef;
    --blue: #4a83dc;
    --dark-blue: #0d59ab;
    --light-blue: #66c6e4;
    --dark-green: #005a47;
    --green: #48d868;
    --pale-green: #94e5a6;
    --mess-mine: #EAFAED;
    --mess-user: #EBF3FC;
    --mess-admin: #FFE9D5;
    --mess-system: #F4F6F8;
    --mess-system-border: #212B360D;
    --mess-ban: #FF5630;
    --count: #1B1B1C;
    --white: #ffffff;
    --extrapale-gray: #ecf4fc;
    --grayish: #eeeeee;
    --pale-gray: #d9d9d9;
    --light-gray: #c1c1c1;
    --gray: #8f8f8f;
    --black: #191F2D;
    --total-black: #000;
    --btn-1: linear-gradient(120.98deg, #4A83DC 48.77%, #3266B8 85.89%);
    --region: #48D868CC;
    --param: #9DC4EF33;
    --param-active: #9dc4ef;
    --param-hover: #191F2D;
    --menu: #637381;
    --menu-active: #9DC4EF33;
    --menu-hover: #9DC4EF33;
    --menu-text: #4A83DC;
    --arrow: #4A83DC;
    --badge: #4A83DC;
    --login-icon: #44F06A;
    --login-key: #000;
    --review-color: #C7DFF933;


    --letter: #fff;
    --search-game-border: #9DC4EF;
    --search-game-text: #9DC4EF;
    --label: #ffffff;
    --glass: linear-gradient(92.32deg,
            rgba(255, 255, 255, 0) 16.31%,
            rgba(245, 251, 255, 0.3) 97.01%),
        rgba(255, 255, 255, 0.3);
    --shadow: 0px 2px 0px rgba(255, 255, 255, 0.8),
        0px 5px 25px rgba(95, 95, 95, 0.08);
    --shadowMobile: 0px 1px 0px rgba(255, 255, 255, 0.5),
        1px 1px 10px rgba(100, 100, 100, 0.15);
    --subtract: url(imgs/Subtract.svg);
    --menu-substract: url(imgs/MenuSubtract.svg);
}

:root[data-theme='dark'] {
    --body-bg: #191F2D;
    --second-bg: #232B42;
    --footer-bg: #232B42;
    --font-color: rgb(255 255 255 / 95%);
    --switcher: #262F45;
    --extrapale-blue: #102250;
    --pale-blue: #9DC4EF;
    --blue: #4a83dc;
    --dark-blue: #9dc4ef;
    --light-blue: #0F86AC;
    --dark-green: #94e5a6;
    --green: #48d868;
    --pale-green: #005a47;
    --mess-mine: #43536A;
    --mess-user: #919EAB29;
    --mess-admin: #FFFCFA4F;
    --mess-system: #919EAB29;
    --mess-system-border: #FFFFFF0D;
    --mess-ban: #EE2A2338;
    --count: #9DC4EF33;
    --white: #000;
    --extrapale-gray: #43536A;
    --extrapale-line: #43536A;
    --grayish: #374050;
    --pale-gray: #4F5D70;
    --light-gray: #7D8999;
    --gray: #B7C0CD;
    --black: #fff;
    --total-black: #fff;
    --btn-1: #323847;
    --menu: #E2EDFA;
    --menu-active: #47567B;
    --menu-hover: #374260;
    --menu-text: #FFFFFF;
    --arrow: #43536A;
    --login-icon: #47567B;
    --login-key: #2B3651;
    --review-color: linear-gradient(137deg,
            rgba(35, 43, 66, 0.09) 15.10%,
            rgba(22, 30, 49, 0.30) 100%),
        rgba(50, 62, 94);


    --letter: #47567B;
    --badge: #FFFFFF;
    --search-game-border: rgba(0, 0, 0, 0);
    --search-game-text: #FFFFFFF2;
    --region: #7D8999;
    --param: linear-gradient(0deg, rgba(50, 62, 94, 0.8), rgba(50, 62, 94, 0.8)),
        linear-gradient(92.32deg, rgba(35, 43, 66, 0.09) 16.31%, rgba(22, 30, 49, 0.3) 97.01%);
    --param-active: #47567B;
    --param-hover: #E5F2FF;
    --label: linear-gradient(137deg,
            rgba(35, 43, 66, 0.09) 15.10%,
            rgba(22, 30, 49, 0.30) 100%),
        rgba(50, 62, 94);

    --glass: linear-gradient(137deg,
            rgba(35, 43, 66, 0.09) 15.10%,
            rgba(22, 30, 49, 0.30) 100%),
        rgba(50, 62, 94);
    --shadow: 0px 2px 0px 0px #38435C, 0px 4px 10px 0px rgba(48, 57, 79, 0.50);
    --shadowMobile: 0px 1px 0px 0px rgba(56, 67, 92, .75), 1px 1px 5px 0px rgba(48, 57, 79, 0.25);
    --subtract: url(imgs/SubtractDark.svg);
    --menu-substract: url(imgs/MenuSubtractDark.svg);
}



/* mixins */
@mixin flex($dir: row, $x: center, $y: center, $wrap: nowrap) {
    display: flex;
    flex-direction: $dir;
    justify-content: $x;
    align-items: $y;
    flex-wrap: $wrap;
}

@mixin no-appearance {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    outline: none;
}

@mixin safariFix {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    isolation: isolate;
    transform: translateZ(0);
}

@mixin absBG($z: 1, $top: 0%, $h: 100%) {
    position: absolute;
    top: $top;
    left: 0px;
    width: 100%;
    height: $h;
    z-index: -1;
}

@mixin clamp($count, $line, $strict: false) {
    display: -webkit-box;
    -webkit-line-clamp: $count;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: $line;

    @if $strict {
        height: $line * $count;
    }

    @else {
        max-height: $line * $count;
    }
}

@mixin list {
    list-style: none;
    padding-left: 0px;
}

@mixin shadow {
    box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8),
        0px 4px 15px rgba(100, 100, 100, 0.08);
    backdrop-filter: blur(4px);
}

@mixin boxShadow($size: 15px) {
    box-shadow: 0px 4px $size rgba(100, 100, 100, 0.08);
}

@mixin lightShadow {
    filter: drop-shadow(0px 4px 15px rgba(100, 100, 100, 0.08));
}

@mixin shadowMobile {
    box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5),
        1px 1px 10px rgba(100, 100, 100, 0.15);
    backdrop-filter: blur(2.5px);
}

@mixin blob {
    box-shadow: inset 0px 1px 3px rgba(245, 201, 249, 0.47);
    filter: drop-shadow(0px 1px 3px rgba(70, 67, 118, 0.2));
}

@mixin btnStyle {
    box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.3),
        0px 3px 10px rgba(156, 156, 156, 0.2);
}

@mixin blurShadow {
    box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8),
        0px 5px 25px rgba(95, 95, 95, 0.08);
    backdrop-filter: blur(5px);
}

@mixin bgTextureGradient($grad: linear-gradient(109.93deg, #3399ab 26.37%, #78d848 59.52%, #91ee8f 91.99%)) {
    background: url(imgs/bg/texture.png), $grad;
    background-size: cover, 100% 100%;
    background-repeat: no-repeat;
    background-blend-mode: overlay, normal;
}

@mixin placeholder($color, $fw: 400) {

    &:-moz-placeholder,
    &::-moz-placeholder,
    &::-webkit-input-placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
        color: $color;
        font-weight: $fw;
    }

    &::placeholder {
        color: $color;
        font-weight: $fw;
    }
}

@mixin rounedImg($w) {
    width: $w;
    height: $w;
    border-radius: 50%;
    object-fit: cover;
}

@mixin roundedImg($w, $rad: 50%, $fit: cover) {
    width: $w;
    height: $w;
    border-radius: $rad;
    object-fit: $fit;
}

@mixin textEllipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}




::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: var(--switcher);
    border-radius: 6px;
    @include shadow();

    &:hover,
    &:active {
        background: rgba($color: var(--switcher), $alpha: 0.8);
    }
}

::-webkit-scrollbar-thumb {
    background-color: var(--pale-blue);
    border-radius: 6px;
    transition: 0.2s;

    &:hover {
        background-color: var(--light-blue);
    }

    &:active {
        background-color: var(--blue);
    }
}

::-webkit-scrollbar-button:vertical:start:decrement,
::-webkit-scrollbar-button:vertical:end:increment,
::-webkit-scrollbar-button:horizontal:start:decrement,
::-webkit-scrollbar-button:horizontal:end:increment {
    display: none;
}

/* Typography */
a,
a:hover,
a:focus,
a:active {
    color: inherit;
    outline: none;
    text-decoration: none;
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
span,
a,
button,
form,
fieldset,
legend,
select,
input,
textarea,
button,
div,
blockquote,
cite,
figure,
figcaption,
table,
ul,
ol,
dl,
li,
address {
    line-height: 135%;
    margin-bottom: 0px;
}

h1,
.h1 {
    font-family: $title-font;
    font-size: 3.6em;
    font-weight: normal;
    text-align: left;
    line-height: 120%;
    margin-bottom: 0.2em;

    @media (max-width: $bp-xl) {
        font-size: 3.5em;
    }

    @media (max-width: $bp-lg) {
        font-size: 3.2em;
    }

    @media (max-width: $bp-md) {
        font-size: 2.75em;
    }

    @media (max-width: $bp-sm) {
        font-size: 2.3em;
    }
}

h2,
.h2 {
    font-family: $title-font;
    font-size: 3em;
    font-weight: normal;
    text-align: left;
    margin-bottom: 0.67em;

    @media (max-width: $bp-md) {
        font-size: 2.75em;
    }

    @media (max-width: $bp-sm) {
        font-size: 2.3em;
        margin-bottom: 0.5em;
    }
}

h3 {
    font-size: 1.6em;
    font-weight: 400;
    text-align: left;
    margin-bottom: 0.2em;

    @media (max-width: $bp-lg) {
        font-size: 1.4em;
    }
}

h4 {
    font-size: 1.3em;
    font-weight: 600;
    text-align: left;
    margin-bottom: 0.2em;

    @media (max-width: $bp-lg) {
        font-size: 1.2em;
    }

    @media (max-width: $bp-md) {
        font-size: 1.1em;
    }
}

h5 {
    font-size: 1.2em;
    font-weight: 400;
    text-align: left;
    margin-bottom: 0px;

    @media (max-width: $bp-lg) {
        font-size: 1.1em;
    }

    @media (max-width: $bp-md) {
        font-size: 1em;
    }
}

h6 {
    font-size: 1em;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0px;
}

.topic {
    background-color: var(--blue);
    color: $white !important;
    padding: 1em 2em;
    border-radius: $radius;

    h1,
    h2,
    a {
        line-height: 100%;
        color: inherit !important;
    }
}

.fs {
    &-08 {
        font-size: 0.8em;
    }

    &-09 {
        font-size: 0.9em;
    }

    &-10 {
        font-size: 1em;
    }

    &-11 {
        font-size: 1.1em;
    }

    &-12 {
        font-size: 1.2em;
    }

    &-13 {
        font-size: 1.3em;
    }

    &-14 {
        font-size: 1.4em;
    }

    &-15 {
        font-size: 1.5em;
    }

    &-16 {
        font-size: 1.6em;
    }

    &-17 {
        font-size: 1.7em;
    }

    &-18 {
        font-size: 1.8em;
    }

    &-19 {
        font-size: 1.9em;
    }

    &-20 {
        font-size: 2em;
    }

    &-25 {
        font-size: 2.5em;
    }

    &-30 {
        font-size: 3em;
    }

    &-35 {
        font-size: 3.5em;
    }

    &-40 {
        font-size: 4em;
    }
}

.fw {
    &-3 {
        font-weight: 300;
    }

    &-4 {
        font-weight: 400;
    }

    &-5 {
        font-weight: 500;
    }

    &-6 {
        font-weight: 600;
    }

    &-7 {
        font-weight: 700;
    }

    &-8 {
        font-weight: 800;
    }

    &-9 {
        font-weight: 900;
    }
}

.green {
    color: var(--green);
}

.white {
    color: var(--white);
}

.light-gray {
    color: var(--light-gray);
}

.gray {
    color: var(--gray);
}

.pale-blue {
    color: var(--pale-blue);
}

.blue {
    color: var(--blue);
}

.dark-blue {
    color: var(--dark-blue);
}

.rose {
    color: $rose;
}

.black {
    color: var(--black);
}

.total-black {
    color: var(--total-black);
}

.title-font {
    font-family: $title-font;
}

.bg-blue {
    background-color: var(--blue);
}

.ruble {
    border-radius: 50%;
    border: 1px solid white;
}

.lh-n {
    line-height: normal;
}

/* Form elements */
input,
textarea {
    --input-bg: var(--body-bg);
    background: var(--input-bg);
    color: var(--font-color);
    border: 1px solid var(--light-gray);
    border-radius: $radius;
    padding: 0.725em 1.65em;
    width: 100%;
    outline: none;
    display: block;
    transition: $transition;
    @include placeholder(var(--light-gray), 400);

    @media (max-width: $bp-xxl) {
        padding: .6em 1.2em;
        padding-right: 2.4em;
    }

    &.p-blue {
        border: 1px solid var(--pale-blue);
        @include placeholder(var(--pale-blue), 400);
    }

    &:hover,
    &:focus,
    &:active {
        border: 1px solid var(--blue);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px var(--input-bg) inset !important;
        -webkit-text-fill-color: var(--font-color) !important;
    }
}

input[type="search" i]::-webkit-search-cancel-button {
    cursor: pointer;
}

input {
    &[type="checkbox"] {
        @include no-appearance();
        width: 20px;
        height: 20px;
        border: 1px solid var(--green);
        border-radius: 4px;
        box-shadow: none;
        padding: 0px;
        margin: 0px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 50%;
            height: 40%;
            border-color: transparent;
            border-width: 0px 0px 3px 3px;
            border-style: solid;
            transform: translate(-50%, -65%) rotate(-45deg);
            transition: border-color $transition;
        }

        &:checked {
            &::before {
                border-color: var(--green);
            }
        }
    }

    &[type="radio"] {
        @include no-appearance;
        width: 20px;
        height: 20px;
        border: 1px solid var(--green);
        background-color: transparent;
        border-radius: 50%;
        box-shadow: none;
        padding: 0px;
        margin: 0px;
        transition: $transition;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 4px;
            left: 4px;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background-color: var(--green);
            transition: $transition;
            opacity: 0;
        }

        &:checked::before {
            opacity: 1;
        }
    }

    &.switch {
        @include no-appearance;
        width: 40px;
        height: 20px;
        border: none;
        border-radius: 10px;
        background-color: var(--light-gray);

        &::before {
            content: "";
            position: absolute;
            top: 2px;
            left: 3px;
            height: 16px;
            width: 16px;
            border-radius: 50%;
            background-color: var(--white);
            transition: $transition;
            opacity: 1;
            border: none;
            transform: unset;
        }

        &:checked {
            background-color: var(--green);

            &::before {
                left: calc(100% - 19px);
            }
        }
    }

    &[type="search"] {
        padding-right: 2.5em;
        background-image: url(imgs/SearchIcon.svg);
        background-repeat: no-repeat;
        background-position: calc(100% - 0.67em) 50%;
        background-size: 1.6em;
    }

    &[type="number"] {

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            @include no-appearance();
        }
    }

    &.code {
        font-family: "Bebas";
        font-size: 2.3em;
        padding: 0px 10px;
        text-align: center;
        line-height: 120%;
    }
}

label {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
        flex: 1;
    }
}

.input-rating {
    input {
        display: none;
    }

    .stars {
        display: flex;
        flex-direction: row-reverse;
        width: fit-content;

        button {
            display: flex;
            color: var(--blue);
            font-size: 2.15em;

            &:not(:last-child) {
                margin-left: 0.2em;
            }

            svg {
                path {
                    stroke: var(--blue);
                    fill: var(--white);
                    transition: $transition;
                }
            }

            &:hover,
            &.active,
            &.active~button,
            &:hover,
            &:hover~button {
                svg {
                    path {
                        fill: var(--blue);
                    }
                }
            }
        }
    }
}

.password {
    position: relative;

    input {
        font-size: inherit;
        font-weight: inherit;
        position: relative;
        z-index: 1;
    }

    button {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 5;
        color: var(--gray);
        height: 100%;
        width: 2em;
        font-size: 1.6em;
        @include flex();

        svg {
            path {
                stroke-width: 1.25px;
            }
        }
    }
}

select {
    @include no-appearance();
    cursor: pointer;
    min-width: 250px;
    border-radius: $radius;
    padding: 0.75em 1.65em;
    border: none;
    @include boxShadow(12px);
    background: url(imgs/chevron-down.svg), var(--second-bg);
    color: inherit;
    background-repeat: no-repeat;
    background-position: calc(100% - 0.67em) 50%, 50% 50%;
    background-size: 1.6em, cover;
    transition: $transition;

    &:hover,
    &:focus {
        background-color: var(--extrapale-gray);
    }

    @media (max-width: $bp-md) {
        min-width: 200px;
    }
}

.labeled-input {
    --bg: var(--body-bg);
    position: relative;

    label {
        position: absolute;
        z-index: 10;
        top: 0px;
        left: 1em;
        transform: translateY(-60%);
        width: fit-content;
        background: var(--bg);
        color: var(--gray);
        padding: 0 0.67em;
    }

    input,
    textarea {
        --input-bg: var(--bg);
        position: relative;
        z-index: 1;
        border-radius: $radius;

        &:hover+label,
        &:focus+label,
        &:active+label {
            color: var(--blue);
        }
    }

    select {
        border-radius: $radius;
        width: 100%;
        border: 1px solid var(--light-gray);
        box-shadow: none;
        background: url(imgs/chevron-down-gray.svg) transparent;
        background-repeat: no-repeat;
        background-position: calc(100% - 0.67em) 50%;
        background-size: 1.6em;
        min-width: 150px;

        &:hover,
        &:focus,
        &:active {
            background: url(imgs/chevron-down.svg) transparent;
            background-repeat: no-repeat;
            background-position: calc(100% - 0.67em) 50%;
            background-size: 1.6em;
            border: 1px solid var(--blue);
        }
    }

    &:hover,
    &:focus,
    &:active {
        label {
            color: var(--blue);
        }
    }
}



.files {
    &-label {
        position: relative;
        @include flex();
        transition: $transition;

        &:hover {
            cursor: pointer;
        }

        input {
            position: absolute;
            z-index: -1;
            opacity: 0;
            width: 1px;
            height: 1px;
            top: 0px;
            left: 0px;
            padding: 0px;
        }

        .icon {
            @include flex();
            width: 2.5em;
            height: 2.5em;
            border-radius: 50%;
            border: 1px solid rgba($color: var(--pale-blue), $alpha: 0.35);

            svg {
                color: var(--blue);
                font-size: 1.5em;
                stroke-width: 1.25px;
            }
        }

        .ind {
            position: absolute;
            bottom: -0.25em;
            right: 0px;
            color: var(--blue);
            font-weight: 600;
            line-height: 1em;
            font-size: 0.9em;

            &:empty {
                display: none;
            }
        }
    }

    &-list {
        @include list();
        @include flex(row, flex-start, stretch, nowrap);

        li {
            img {
                @include roundedImg(100px, $radius, cover);
            }
        }
    }
}

.hv-100 {
    min-height: calc(100vh - var(--h-height) - var(--f-height) - 4em);
}

button {
    background: none;
    border: none;
    padding: unset;
    display: block;
    font-family: inherit;
    outline: none;
    color: inherit;

    &[disabled]:not(.btn) {
        opacity: 0.5 !important;
    }
}

.btn {
    @include flex(row, center, center, nowrap);
    padding: 0.25rem .5rem;
    text-align: center;
    width: fit-content;
    border-radius: $radius;

    &:active,
    &:hover {
        transition: $transition;
    }

    //голубая градиентная кнопка
    &-1,
    &-primary {
        @extend .btn;
        color: $white !important;
        background: var(--btn-1);
        background-position: left center !important;

        &:hover,
        &:focus {
            background-position: right center;
        }

        &:active,
        &.active {
            background-position: center center;
        }

        &[disabled] {
            background: var(--light-gray) !important;
        }
    }

    //белая кнопка
    &-2,
    &-light {
        @extend .btn;
        background: var(--label);
        color: var(--font-color);
        @include lightShadow();
        backdrop-filter: blur(5px);
        border-radius: $radius;

        &:hover,
        &:active,
        &.active {
            background-color: var(--menu-active);
        }


        &[disabled] {
            background: (--gray-bg) !important;
        }
    }

    //розовая кнопка
    &-3,
    &-danger {
        @extend .btn;
        background: $rose;
        color: $white !important;
        box-shadow: var(--shadow);
        backdrop-filter: blur(5px);
        border-radius: $radius;

        &:hover,
        &:focus {
            background: var(--dark-blue);
        }

        &:active,
        &.active {
            background: var(--dark-green);
        }

        &[disabled] {
            background: var(--light-gray) !important;
        }
    }

    //черная кнопка
    &-4,
    &-dark {
        @extend .btn;
        background: var(--total-black);
        color: var(--white);
        box-shadow: var(--shadow);
        backdrop-filter: blur(5px);
        border-radius: $radius;

        &:hover,
        &:focus {
            color: var(--white);
            background: var(--dark-blue);
        }

        &:active,
        &.active {
            color: var(--white);
            background: var(--dark-green);
        }

        &[disabled] {
            background: var(--light-gray) !important;
        }
    }

    //голубая кнопка
    &-5 {
        @extend .btn;
        background: var(--blue);
        color: $white !important;
        box-shadow: var(--shadow);
        backdrop-filter: blur(5px);
        border-radius: $radius;

        &:hover,
        &:focus {
            background: var(--dark-blue);
        }

        &:active,
        &.active {
            background: var(--dark-green);
        }

        &[disabled] {
            background: var(--light-gray) !important;
        }
    }

    //зеленая кнопка
    &-6,
    &-success {
        @extend .btn;
        background: var(--green);
        color: var(--white);
        box-shadow: var(--shadow);
        backdrop-filter: blur(5px);
        border-radius: $radius;

        &:hover,
        &:focus {
            color: var(--white);
            background: var(--light-blue);
        }

        &:active,
        &.active {
            color: var(--white);
            background: var(--dark-green);
        }

        &[disabled] {
            background: var(--light-gray) !important;
        }
    }

    &[disabled] {
        background: var(--light-gray);
    }
}

.loader {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
}

.input-file-simple {
    position: relative;
    color: var(--pale-blue);
    transition: $transition;
    padding: .8em 1.25em;

    &:hover {
        cursor: pointer;
        color: var(--blue);
    }

    input {
        position: absolute;
        z-index: -1;
        opacity: 0;
        width: 1px;
        height: 1px;
        top: 0px;
        left: 0px;
        padding: 0px;
    }

    svg {
        color: currentColor;
        font-size: 1.5em;
    }

    .ind {
        position: absolute;
        bottom: 0.25em;
        right: 0.25em;
        color: var(--white);
        line-height: 1em;
        font-size: 0.75em;
        background: var(--blue);
        width: 1.4em;
        height: 1.4em;
        border-radius: 50%;
        @include flex();

        &:empty {
            display: none;
        }
    }
}

.lang-switcher {
    position: relative;

    button {
        width: 100%;
        font-size: 1em;
        padding: 0.1em 0.2em;
        @include flex(row, space-between, center, nowrap);
        color: var(--gray);
    }

    img {
        width: 1.6em;
        height: 1.6em;
        object-fit: cover;
        border-radius: 50%;
        @include shadow();
        margin-right: 0.5em;
    }

    ul {
        @include list();
        position: absolute;
        z-index: 2;
        top: 100%;
        left: 0px;
        width: 100%;
        height: auto;
        @include flex(column, flex-start, stretch, nowrap);

        li {
            width: 100%;
            margin: 0px !important;
        }
    }

    // &.opened {

    // }
}

.star-rating {
    @include flex(row, flex-start, center, nowrap);
    color: var(--blue);
    font-size: 1.34em;

    svg {
        &:not(:first-child) {
            margin-left: 3px;
        }

        &.filled {
            path {
                fill: var(--blue);
            }
        }
    }
}

.link {
    color: var(--blue);
    text-decoration: underline;
    transition: $transition;

    &:hover,
    &:focus,
    &:active {
        color: var(--dark-blue);
        text-decoration: underline;
    }

    &-2 {
        color: var(--blue);
        text-decoration: none;
        transition: $transition;

        &:hover,
        &:focus,
        &:active {
            color: var(--light-blue);
            text-decoration: none;
        }
    }
}

.return-link {
    @include flex();
    font-size: 2em;
}

.return-title {
    display: none;

    @media (max-width: $bp-lg) {
        @include flex(row, flex-start, center, nowrap);
        margin-bottom: 2em;
    }
}

.themes-switcher {
    input {
        width: 30px;
        height: 10px;
        background-color: var(--switcher);
        border-radius: 5px;
        border: none !important;

        &::before {
            content: '';
            top: 0px;
            left: auto;
            right: 0;
            width: 10px;
            height: 100%;
            border-radius: 50%;
            border: none !important;
            background-image: url(imgs/icons/moon.svg);
            background-color: transparent;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            transform: scale(2.6);
            transition: $transition;
            opacity: 1;
        }

        &:checked {
            border: none !important;

            &::before {
                border: none !important;
                left: 0;
                right: auto;
                background-image: url(imgs/icons/sun.svg);
            }
        }
    }
}

.min-250 {
    min-width: 250px;
}

@media (max-width: $bp-sm) {
    .w-xs-100 {
        width: 100%;
    }
}

.flex-1 {
    flex: 1;
}

.mb-6 {
    margin-bottom: 6em;

    @media (max-width: $bp-md) {
        margin-bottom: 5em;
    }

    @media (max-width: $bp-sm) {
        margin-bottom: 4em;
    }
}

.ms-6 {
    margin-left: 6em;

    @media (max-width: $bp-md) {
        margin-left: 5em;
    }

    @media (max-width: $bp-sm) {
        margin-left: 4em;
    }
}

.pb-6 {
    padding-bottom: 6em;

    @media (max-width: $bp-md) {
        padding-bottom: 5em;
    }

    @media (max-width: $bp-sm) {
        padding-bottom: 4em;
    }
}

.ps-6 {
    padding-left: 6em;

    @media (max-width: $bp-md) {
        padding-left: 5em;
    }

    @media (max-width: $bp-sm) {
        padding-left: 4em;
    }
}

/* Breadcrumbs */
.breadcrumbs {
    margin-left: 2em;
    margin-bottom: 2em;
    color: var(--light-gray);

    @media (max-width: $bp-xl) {
        margin-bottom: 1.5em;
    }

    ul {
        @include list();
        @include flex(row, flex-start, center, wrap);

        li {
            a {
                font-size: 1.2em;
                font-weight: 500;

                @media (max-width: $bp-lg) {
                    font-size: 1.1em;
                }

                @media (max-width: $bp-md) {
                    font-size: 1em;
                }
            }

            &:not(:first-child) {
                margin-left: 1em;
                padding-left: 1.4em;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0px;
                    display: block;
                    height: 100%;
                    width: 8px;
                    background: url(imgs/arrow-mini.svg) no-repeat center;
                    background-size: contain;

                    @media (max-width: $bp-lg) {
                        width: 7px;
                    }

                    @media (max-width: $bp-md) {
                        width: 6px;
                    }
                }
            }
        }
    }
}

/* Pagination */
nav.pagination {
    justify-content: center;

    ul {
        @include list();
        @include flex();

        li {
            &:not(:first-child) {
                margin-left: 1.3em;
            }

            a {
                width: 2em;
                height: 2em;
                border-radius: 50%;
                background-color: transparent;
                color: var(--blue);
                @include flex();
                transition: $transition;
                border: none;

                &:focus {
                    box-shadow: none;
                }

                svg {
                    font-size: 1.2em;
                }

                &:hover {
                    background-color: var(--extrapale-gray);
                    color: var(--menu-text);
                }

                &:active,
                &.active {
                    background-color: var(--extrapale-gray);
                }
            }
        }
    }
}

.accordion {
    --bs-accordion-color: #1b1b1c;
    --bs-accordion-btn-color: #4a83dc;
    --bs-accordion-active-color: #4a83dc;
    --bs-accordion-btn-bg: transparent;
    --bs-accordion-active-bg: transparent;
    --bs-accordion-btn-padding-y: 0.5em;
    --bs-accordion-btn-padding-x: 0px;
    --bs-accordion-body-padding-x: 0px;
    --bs-accordion-border-color: rgba(157, 196, 239, 0.3);
    --bs-accordion-btn-icon-width: 2em;
    --bs-accordion-btn-icon: url(imgs/chevron-down-blue.svg);
    --bs-accordion-btn-active-icon: url(imgs/chevron-down-blue.svg);

    &-button {
        box-shadow: unset;
        font-weight: 600;
    }
}



.dropdown {
    .select {
        border-radius: 0.67em;
        width: 100%;
        border: 1px solid var(--light-gray);
        box-shadow: none;
        min-width: 150px;
        padding: 0.725em 1.65em;

        &-label {
            border-radius: $radius;
            position: absolute;
            z-index: 10;
            top: 0px;
            left: 1em;
            transform: translateY(-60%);
            width: fit-content;
            background: var(--label);
            color: var(--gray);
            padding: 0 0.67em;

        }

        &:hover,
        &:focus,
        &:active,
        &.active {
            border: 1px solid var(--blue);

            .select-label {
                color: var(--blue) !important;
            }
        }
    }


    &-toggle {
        @include flex();

        &::after {
            display: none;
        }
    }

    &-menu {
        --bs-dropdown-spacer: 0.125rem;
        --bs-dropdown-font-size: 1em;
        --bs-dropdown-color: var(--font-color);
        --bs-dropdown-bg: var(--second-bg);
        --bs-dropdown-border-color: #f4f6f8;
        --bs-dropdown-border-radius: 10px;
        --bs-dropdown-border-width: 0px;
        --bs-dropdown-inner-border-radius: 9px;
        --bs-dropdown-link-color: var(--font-color);
        --bs-dropdown-link-hover-color: var(--font-color);
        --bs-dropdown-link-hover-bg: var(--pale-gray);
        --bs-dropdown-link-active-color: #fff;
        --bs-dropdown-link-active-bg: var(--blue);
        --bs-dropdown-item-padding-x: 1em;
        --bs-dropdown-item-padding-y: 0.5em;
        --bs-dropdown-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
        box-shadow: var(--bs-dropdown-box-shadow);
        padding: 0px;
        overflow: hidden;
        @include safariFix();
    }

    &-item {
        display: block !important;
        width: 100%;


        padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
        text-align: left;
        justify-content: flex-start !important;
        transition: $transition;


        &:active,
        &.active {
            background: var(--param-active);
            color: white;

        }
    }

}

/* Box */
.list-wrapping {
    background: var(--glass);
    box-shadow: var(--shadow);
    backdrop-filter: blur(5px);
    border-radius: $radius;
    --padX: 1.3em;
    --padY: 1em;

    @media (max-width: $bp-xl) {
        background: transparent;
        border-radius: 0px;
        box-shadow: none;
        --padX: 0px;
    }

    &-top {
        background-color: var(--extrapale-gray);
        border-radius: $radius $radius 0px 0px;
        padding: var(--padY) 1em;

        @media (max-width: $bp-xl) {
            display: none;
        }

        ul {
            @include list();
            @include flex();
            padding: 0.25em 0em;
            font-weight: 700;
            font-size: 1.2em;

            &.line-1 {
                padding: 0 1.2em;
                font-weight: normal;

                .category {
                    width: 160px;
                    text-align: left;

                }

                .server {
                    width: 160px;
                    text-align: left;

                }

                .title {
                    text-align: left;
                    width: 100%;
                    flex: 1;
                }

                .count {
                    width: 120px;
                    text-align: right;
                }

                .price {
                    width: 120px;
                    text-align: right;
                }

            }

            &.line-2 {
                .category {
                    width: 150px;
                    text-align: left;

                }

                .server {
                    width: 200px;
                    text-align: left;

                }

                .title {
                    flex: 1;
                }

                .count {
                    width: 120px;
                    text-align: right;
                }

                .price {
                    width: 120px;
                    text-align: right;
                }

                .btns {
                    width: 40px;
                }
            }

            &.line-3 {
                display: grid;
                grid-template-columns: 140px 110px 1fr 200px 130px 80px;
                grid-template-rows: 1fr;
                grid-template-areas: "date id descr seller status price";

                @media (max-width: $bp-xxl) {
                    grid-template-columns: 83px 90px 1fr 200px 130px 80px;
                    font-size: 1em;
                }

                .date {
                    grid-area: date;
                }

                .id {
                    grid-area: id;
                }

                .descr {
                    grid-area: descr;
                }

                .seller {
                    grid-area: seller;
                }

                .status {
                    grid-area: status;
                }

                .price {
                    grid-area: price;
                }
            }

            &.line-operation {
                display: grid;
                grid-template-columns: 21% 21% 21% 21% 16%;
                grid-template-rows: 1fr;
                grid-template-areas: "date id type stat sum";

                .date {
                    grid-area: date;
                }

                .id {
                    grid-area: id;
                }

                .type {
                    grid-area: type;
                }

                .stat {
                    grid-area: stat;
                }

                .sum {
                    grid-area: sum;
                }
            }

            &.line-appeal {
                display: grid;
                grid-template-columns: 35% 13% 22% 20% 10%;
                grid-template-rows: 1fr;
                grid-template-areas: "subject id status date btns";

                .subject {
                    grid-area: subject;
                }

                .id {
                    grid-area: id;
                }

                .status {
                    grid-area: status;
                }

                .date {
                    grid-area: date;
                }

                .btns {
                    grid-area: btns;
                }
            }
        }
    }

    &-main {
        padding: 0px;

        ul {
            @include list();

            li {
                border-bottom: 1px solid rgba($color: var(--pale-blue), $alpha: 0.2);

                @media (max-width: $bp-xl) {
                    border-bottom: none;
                }
            }
        }
    }

    &-bottom {
        padding: var(--padX) var(--padX);

        @media (max-width: $bp-xl) {
            padding: var(--padY) 0 0;
        }
    }
}


body {
    margin: 0;
    font-family: $main-font, "Arial", "Tahoma", "Verdana";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 13px;
    font-weight: 400;
    background-color: var(--body-bg);
    color: var(--font-color);
    overflow-x: hidden;

    @media (max-width: $bp-sm) {
        font-size: 13px;
    }
}

main {
    min-height: calc(100vh - var(--h-height) - var(--f-height));
    padding: 2em 0 1em 0;

    @media (max-width: $bp-lg) {
        min-height: calc(100vh - var(--f-height));
        padding-bottom: var(--f-height);
    }

    @media (max-width: $bp-sm) {
        padding-top: 1em;
    }
}

header {
    background-color: var(--body-bg);
    opacity: 0.9;
    width: 100%;
    position: sticky;
    top: 0;
    left: 0%;
    z-index: 1042;
    height: var(--h-height);

    @media (max-width: $bp-lg) {
        display: none;
    }

    input {
        background: var(--glass);
        border-color: var(--search-game-border);
        @include placeholder(var(--search-game-text));

    }

    .profile-link {
        @include flex();

        img {
            @include rounedImg(30px);
            margin-right: 0.5em;
        }
    }
}

/* Slider */
.home-slider {
    background-image:
        linear-gradient(104deg, rgba(0, 0, 0, 0) 26.89%, #000000 93.68%),
        linear-gradient(0deg, #2C2C2C, #2C2C2C),
        url("/public/imgs/image.png");

    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: left, left, left;
    background-size: auto, auto, cover;
    background-blend-mode: multiply;
    opacity: 1;
    border-radius: $radius;
    padding: 0 6em;
    gap: 3em;
    color: white;
    width: 100%;
    max-width: 96vw;
    height: fit-content;
    display: flex;

    @media (max-width: $bp-xxl) {
        padding: 0 3em;
    }

    @media (max-width: $bp-xl) {
        padding: 0 2em;
    }

    @media (max-width: 991px) {
        padding: 0;
        padding-top: 1em;
        gap: 0;
        flex-direction: column;
        height: fit-content;
        width: 100%;
    }

    .slider {
        width: 40%;

        @media (max-width: $bp-lg) {
            width: 100%;
        }
    }

    .title {

        flex: 1;

        @media (max-width: 991px) {
            width: 100%;
            margin: 2em;
            padding: 0 2em;
            margin-bottom: 0;

        }

        svg {
            margin-bottom: 2em;

            @media (max-width: $bp-xl) {
                margin-bottom: 1em;
            }
        }
    }

    h1 {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-weight: 650;
        margin-bottom: .4em;
        font-size: 2.51em;

        @media (max-width: $bp-xxl) {
            font-size: 2.3em;
        }

        @media (max-width: $bp-xl) {
            font-size: 2em;
        }

        @media (max-width: $bp-lg) {
            font-size: 2.3em;
        }

        @media (max-width: $bp-md) {
            font-size: 2em;
        }

        @media (max-width: $bp-sm) {
            font-size: 1.7em;
        }
    }

    button {
        background: linear-gradient(112.15deg, #44F06A 17.4%, #278A3D 149.26%);
        padding: 1em 2em;
        font-size: .9em;
        height: fit-content;
        color: black;
        transition: $transition;
        transition-property: background-color, color;
        border-radius: $radius;
        border: 1px solid transparent;

        background-size: 200% 100%;

        &:hover {
            border: 1px solid var(--param-hover);
        }

        &:active {
            background: var(--param-active);
        }
    }

    h3 {
        margin-bottom: 1.2em;

        @media (max-width: $bp-xxl) {
            font-size: 1.1em;
        }

        @media (max-width: $bp-xl) {
            font-size: 1em;
        }

        @media (max-width: $bp-lg) {
            display: none;
        }
    }

    .item {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 15px;
        width: fit-content;

        aspect-ratio: 1 / 1;

        @media (max-width: $bp-xxl) {
            gap: 15px;
        }

        @media (max-width: $bp-xl) {
            gap: 10px;
        }



    }

    .main-slider {
        position: relative;
        width: 25% !important;
        height: 100%;
        overflow: hidden;


        @media (max-width: 991px) {
            height: fit-content;
            margin-top: 1em;
            width: 100% !important;
            aspect-ratio: unset;
            border-radius: $radius;
            margin-bottom: 1em;
        }

        img {
            width: 100%;
            aspect-ratio: 1 / 1;
            -webkit-aspect-ratio: 1 / 1;
            -moz-aspect-ratio: 1 / 1;
            -ms-aspect-ratio: 1 / 1;
            -o-aspect-ratio: 1 / 1;
            border-radius: $radius;
            box-sizing: border-box;
            pointer-events: none;
            user-select: none;
        }




        .swiper-wrapper {
            transition-timing-function: linear !important;
            display: flex;
            align-items: stretch;
        }

        .swiper-slide {
            transition-timing-function: linear !important;
            width: 100% !important;
            flex-shrink: 0;
            width: auto; // Убедитесь, что ширина слайда авто
            height: auto; // Убедитесь, что высота слайда авто
            display: flex;

            @media (max-width: 991px) {
                width: 100px !important;
            }



        }
    }
}


/* Catalog */
.sec-catalog {
    padding-left: 6em;

    @media (max-width: $bp-lg) {
        padding: 0;
        width: 100%;
    }

    &-container {
        position: relative;


        @media (max-width: $bp-xl) {
            max-width: 1370px;
        }

        @media (max-width: $bp-lg) {
            padding: 0 1em;
        }
    }



    &-nav {
        position: absolute;
        z-index: 10;
        top: 0%;
        right: calc(100% + 12px);
        width: 60px;
        height: 100%;

        @media (max-width: $bp-lg) {
            display: none;
        }




        @media (max-width: $bp-lg) {
            position: relative;
            top: auto;
            left: auto;
            height: auto;
            margin-left: 1em;
        }

        @media (max-width: $bp-sm) {
            width: 40px;
        }

        .scroll {
            width: 100%;
            max-height: calc(100vh - var(--h-height) - var(--f-height) - 2em);
            overflow-y: scroll;
            padding: 0;

            &::-webkit-scrollbar {
                width: 0;
                /* Скрываем полосу прокрутки */
            }
        }

        .wrap {
            background: var(--label);
            box-shadow: var(--shadow);
            backdrop-filter: blur(5px);
            border-radius: $radius;
            position: sticky;
            top: calc(var(--h-height) + 1em);
            left: 0;
            bottom: var(--f-height);
            width: 100%;
            // height: fit-content;
            max-height: calc(100vh - var(--h-height) - var(--f-height));
            padding: 0.5em;
            transition: $transition;



            @media (max-width: $bp-sm) {
                padding: 0;
            }


            .sort {
                text-align: center;

                .swiper {
                    font-size: 1.3em;
                    text-align: center;
                    width: 100%;
                    height: 100%;
                    max-height: calc(100vh - var(--h-height) - var(--f-height) - 1em);

                    &-slide {
                        height: auto !important;
                        padding: .2em;

                        a {
                            @include flex();
                            color: var(--font-color);
                            position: relative;
                            background-color: transparent;
                            transition: $transition;
                            width: 1.9em;
                            height: 1.9em;
                            border-radius: $radius;
                            white-space: nowrap;


                            &:hover {
                                background-color: var(--menu-active);
                                color: var(--menu-text);
                            }

                            &.active {
                                background-color: var(--menu-active);
                                color: var(--menu-text);
                            }
                        }
                    }
                }


            }

            ul {
                display: none;
            }





            #cut {
                font-size: 1.3em;
                position: absolute;
                right: -1.1em;
                top: 1em;
                width: 40px;
                height: 30px;
                transform: translateY(0.25em);
                @include flex();
                border-radius: 50%;
                overflow: hidden;
                background: var(--menu-substract) no-repeat center bottom;
                background-size: 100% 50%;
                transform: rotate(90deg);

                filter: drop-shadow(-0px 3px 3px rgba(70, 67, 118, 0.2));


                @media (max-width: $bp-lg) {
                    transform: rotate(-90deg);
                    right: unset;
                    left: -1.1em;
                }

                .img {
                    width: 20px;
                    height: 20px;
                    transition: $transition;
                    transform: rotate(-90deg);
                    color: var(--arrow);
                    cursor: pointer;

                    @media (max-width: $bp-lg) {
                        transform: rotate(-90deg);
                    }
                }

                &.opened {
                    background: none;



                    .img {
                        transform: rotate(90deg);

                        @media (max-width: $bp-lg) {
                            transform: rotate(90deg);
                        }
                    }
                }
            }

            &.full {
                @include btnStyle();
                display: block;
                width: auto;
                min-width: max-content;

                .alfabet {
                    width: fit-content;
                    display: inline-block;

                    ul {
                        display: flex;
                        flex-direction: column;
                        flex-wrap: wrap;

                        height: 100%;
                        overflow: hidden;
                        list-style: none;
                        padding: 0;
                        max-height: calc(100vh - var(--h-height) - var(--f-height) - 1em);
                        align-items: center;
                        transition: var(--transition);

                        li {
                            font-size: 1.3em;
                            text-align: center;
                            margin: 0.2em;
                            display: flex;
                            width: fit-content;


                            a {
                                @include flex();
                                color: var(--font-color);
                                position: relative;
                                background-color: transparent;
                                transition: $transition;
                                width: 1.9em;
                                height: 1.9em;
                                border-radius: $radius;
                                white-space: nowrap;

                                &:hover {
                                    background-color: var(--menu-active);
                                    color: var(--menu-text);
                                }

                                &.active {
                                    background-color: var(--menu-active);
                                    color: var(--menu-text);
                                }
                            }
                        }
                    }
                }

                .scroll {
                    display: none;
                }


                form {

                    // border: 1px solid var(--pale-blue);
                    input {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &-nav-mobile {
        z-index: 10;
        position: sticky;
        top: 10px;
        left: auto;
        height: auto;
        margin-right: 1em;
        flex-shrink: 0;

        width: 60px;
        height: 100%;

        @media (min-width: $bp-lg) {
            display: none;
        }

        @media (max-width: $bp-sm) {
            width: 40px;
        }

        .scroll {
            width: 100%;
            max-height: calc(100vh - var(--h-height) - var(--f-height) - 2em);
            overflow-y: scroll;
            padding: 0;

            &::-webkit-scrollbar {
                width: 0;
                /* Скрываем полосу прокрутки */
            }
        }

        .wrap {
            background: var(--label);
            box-shadow: var(--shadow);
            backdrop-filter: blur(5px);
            border-radius: $radius;
            position: sticky;
            top: calc(var(--h-height) + 1em);
            left: 0;
            bottom: var(--f-height);
            width: 100%;
            // height: fit-content;
            max-height: calc(100vh - var(--h-height) - var(--f-height));
            padding: 0.5em;
            transition: $transition;



            @media (max-width: $bp-sm) {
                padding: 0;
            }


            .sort {
                text-align: center;

                .swiper {
                    font-size: 1.3em;
                    text-align: center;
                    width: 100%;
                    height: 100%;
                    max-height: calc(100vh - var(--h-height) - var(--f-height) - 1em);

                    &-slide {
                        height: auto !important;
                        padding: .2em;

                        a {
                            @include flex();
                            color: var(--font-color);
                            position: relative;
                            background-color: transparent;
                            transition: $transition;
                            width: 1.9em;
                            height: 1.9em;
                            border-radius: $radius;
                            white-space: nowrap;


                            &:hover {
                                background-color: var(--menu-active);
                                color: var(--menu-text);
                            }

                            &.active {
                                background-color: var(--menu-active);
                                color: var(--menu-text);
                            }
                        }
                    }
                }


            }

            ul {
                display: none;
            }





            #cut {
                font-size: 1.3em;
                position: absolute;
                right: -1.1em;
                top: 1em;
                width: 40px;
                height: 30px;
                transform: translateY(0.25em);
                @include flex();
                border-radius: 50%;
                overflow: hidden;
                background: var(--menu-substract) no-repeat center bottom;
                background-size: 100% 50%;
                transform: rotate(90deg);

                filter: drop-shadow(-0px 3px 3px rgba(70, 67, 118, 0.2));


                .img {
                    width: 20px;
                    height: 20px;
                    transition: $transition;
                    transform: rotate(-90deg);
                    color: var(--arrow);
                    cursor: pointer;

                }

                &.opened {
                    background: none;



                    .img {
                        transform: rotate(90deg);


                    }
                }
            }

            &.full {
                @include btnStyle();
                display: block;
                width: auto;
                min-width: max-content;

                .alfabet {
                    width: fit-content;
                    display: inline-block;

                    ul {
                        display: flex;
                        flex-direction: column;
                        flex-wrap: wrap;

                        height: 100%;
                        overflow: hidden;
                        list-style: none;
                        padding: 0;
                        max-height: calc(100vh - var(--h-height) - var(--f-height) - 1em);
                        align-items: center;
                        transition: var(--transition);

                        li {
                            font-size: 1.3em;
                            text-align: center;
                            margin: 0.2em;
                            display: flex;
                            width: fit-content;


                            a {
                                @include flex();
                                color: var(--font-color);
                                position: relative;
                                background-color: transparent;
                                transition: $transition;
                                width: 1.9em;
                                height: 1.9em;
                                border-radius: $radius;
                                white-space: nowrap;

                                &:hover {
                                    background-color: var(--menu-active);
                                    color: var(--menu-text);
                                }

                                &.active {
                                    background-color: var(--menu-active);
                                    color: var(--menu-text);
                                }
                            }
                        }
                    }
                }

                .scroll {
                    display: none;
                }


                form {

                    // border: 1px solid var(--pale-blue);
                    input {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &-box {
        flex: 1;

        @media (max-width: $bp-lg) {
            width: 100%;
            @include flex(row, flex-start, stretch, nowrap);
        }

        &-mobile {
            max-width: 100%;
            flex: 1;
        }
    }

    &-part {
        &:not(:first-child) {
            padding-top: 3.33em;
        }

        .element {

            padding: 1em 1.5em;
            background: var(--glass);
            box-shadow: var(--shadow);
            backdrop-filter: blur(5px);
            border-radius: 10px;

            @media (max-width: $bp-sm) {
                padding: 1em;
            }
        }




    }

    .letter {
        font-size: 3.33em;
        font-weight: 700;
        color: rgba(67, 185, 198, 0.26);
        margin-bottom: 1rem;


        @media (max-width: $bp-sm) {
            line-height: 100%;
        }
    }
}

:root[data-theme='light'] .sec-catalog {
    background: url(imgs/bg/gradient.jpg) no-repeat;
    background-size: 100% 100%;
}

.game-card {
    @include flex(row, flex-start, flex-start, nowrap);

    .img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 0.5em;
    }

    h4 {
        margin-bottom: 0.6em;
    }

    .categories {
        @include list();
        @include flex(row, flex-start, center, wrap);
        padding: 0.85em 0.5em 0.5em;

        @media (max-width: $bp-xxl) {
            padding: 0.75em 0em 0;
        }

        li {
            margin-bottom: 0.67em;

            &:not(:last-child) {
                margin-right: 1em;
            }
        }
    }

    &-mini {
        @include flex(row, flex-start, center, nowrap);
        background: var(--label);
        border-radius: $radius;
        @include lightShadow();
        padding: 1.6em 1.2em;
        width: 100%;

        @media (max-width: $bp-sm) {
            padding: 1em 0.75em;
        }

        &:hover,
        &:active,
        &.active {
            background-color: var(--menu-active);
        }

        .img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 50%;
            margin-right: 0.5em;

            @media (max-width: $bp-sm) {
                width: 30px;
                height: 30px;
            }
        }

        h6 {
            font-size: 1.1em;
        }
    }
}

.servers {
    position: relative;
    @include list();
    @include flex(row, flex-start, center, nowrap);
    background: var(--region);
    @include btnStyle();
    width: fit-content;
    border-radius: $radius;
    padding: 0 0.5em;
    box-shadow: var(--shadow);

    li {
        position: relative;
        color: white;
        width: fit-content;
        text-transform: uppercase;
        padding: 0.6em 0.5em;
        transition: $transition;
        cursor: pointer;

        &.indicator {
            transform: translateY(-50%) translateX(-50%);
            padding: 0px;
            position: absolute;
            left: 0.5em;
            top: -1px;
            width: 20px;
            height: 15px;
            background: var(--subtract) no-repeat center bottom;
            background-size: 100% 50%;
            @include flex();

            img {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                filter: drop-shadow(0px 1px 3px rgba(70, 67, 118, 0.2));
            }
        }

        &.active {
            color: black;
        }
    }
}

.sec-promo {
    position: relative;
    @include bgTextureGradient();
    box-shadow: var(--shadow);
    backdrop-filter: blur(5px);
    border-radius: $radius;
    padding: 0 10% 0 4em;
    color: var(--white);
    @include flex(row, space-between, center, nowrap);
    margin-top: 10%;


    @media (max-width: $bp-xxl) {
        padding: 0 2em;
    }

    @media (max-width: $bp-xl) {
        margin-top: 15%;
    }

    @media (max-width: $bp-lg) {
        // padding: 1em;
        // height: 85px;
        display: none;
    }

    h1 {
        font-size: 2.5em;

        @media (max-width: $bp-xxl) {
            font-size: 2.3em;
        }

        @media (max-width: $bp-xl) {
            font-size: 1.6em;
        }


        // @media (max-width: $bp-lg) {
        //     font-size: 3.6em;
        // }

        // @media (max-width: $bp-md) {
        //     font-size: 2em;
        // }

        // @media (max-width: $bp-sm) {
        //     font-size: 3.6em;
        // }
    }

    img {
        width: 25%;
        margin-top: -11%;

        @media (max-width: $bp-xxl) {
            width: 22%;
            margin-top: auto;
        }

        @media (max-width: $bp-lg) {
            // width: 50%;
            margin-top: -15%;
        }

        @media (max-width: $bp-sm) {
            position: absolute;
            bottom: 0px;
            right: 0px;
            width: 55%;
        }
    }

    .text {
        position: relative;
        z-index: 10;
    }
}

/* Blog */
.sec-blog {
    .blog-list {
        @include list();

        &-item {
            position: relative;

            img {
                width: 100%;
                height: auto;
                object-fit: contain;
                border-radius: $radius;
                margin-bottom: 1.3em;


            }

            h5 {
                @include clamp(3, 1.3em);
            }

            &-mobile {
                @include flex(row, flex-start, center, nowrap);
                position: relative;

                img {
                    width: 100px;
                    height: auto;
                    object-fit: contain;
                    border-radius: $radius;
                    margin-right: 1em;
                }

                h5 {
                    flex: 1;
                }
            }
        }
    }
}

.cognition {
    flex: 1;
    @include flex(row, flex-end, center, nowrap);
    background-color: var(--black);
    background-image: linear-gradient(to right, transparent, var(--black)),
        url(imgs/bg/cogito.jpg);
    background-repeat: no-repeat;
    background-size: 50% 100%, 75% auto;
    background-position: 60% 50%, -10% 50%;
    color: var(--white);
    padding: 0em 2em;
    height: 60px;
    text-align: right;
    border-radius: $radius;
    transition: $transition;

    &:hover,
    &:focus,
    &:active {
        color: var(--light-blue);
    }
}

.page-blog {
    .article-mini {
        position: relative;

        img {
            width: 100%;
            height: auto;
            object-fit: contain;
            border-radius: $radius $radius 0 0;

        }

        figcaption {
            background-color: var(--white);
            border-radius: 0 0 $radius $radius;
            @include lightShadow();
            padding: 1.5em 1.5em 0.6em;

            @media (max-width: $bp-xl) {
                padding: 1em 1.25em 0.6em;
            }

            @media (max-width: $bp-sm) {
                padding: 0.6em 1em;
            }

            h5 {
                font-weight: 600;
                color: var(--total-black);
                @include clamp(2, 1.2em, true);

                a {
                    line-height: 1.2em;
                }
            }

            time {
                display: block;
                text-align: right;
                margin-top: 0.5em;
                color: var(--pale-blue);
                font-weight: 500;
                font-size: 1.2em;

                @media (max-width: $bp-lg) {
                    font-size: 1em;
                }
            }
        }
    }

    article {
        p {
            color: var(--total-black);
        }

        img {
            max-width: 100%;
            height: auto;
            object-fit: contain;
            border-radius: $radius;
            margin-bottom: 2em;

            @media (max-width: $bp-sm) {
                margin-bottom: 1em;
            }
        }
    }
}

/* bottom background */
.sec-bottom {
    background: url(imgs/bg/gradient.jpg) no-repeat center;
    background-size: 100% 100%;
}

:root[data-theme='dark'] .sec-bottom {
    background: var(--body-bg);
}


/* Chat */
.chat {
    position: relative;
    background: var(--label);
    box-shadow: var(--shadow);
    border-radius: 10px;
    overflow: hidden;


    &-window {
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        height: calc(100vh - var(--h-height) - 7.5em - var(--textarea-height));
        overflow-y: auto;

        &.system {
            height: calc(100vh - var(--h-height) - 7.5em) !important;
        }

        &.off {
            overflow: unset;

            .scroll-button {
                position: absolute;
                top: 1em;
                left: 50%;
                transform: translateX(-50%);
                width: fit-content;
                background: var(--btn-1);
                padding: .9em 1.4em;
                font-size: .9em;
                color: #fff;
                transition: $transition;
                transition-property: background-color, color;
                border-radius: 8px;
                z-index: 20;
                border: 1px solid transparent;

                &:hover {
                    border: 1px solid var(--param-hover);
                }

                &:active {
                    background: var(--param-active);
                }
            }


        }

        padding: 1em 1.5em;

        @media (max-width: $bp-xl) {
            height: calc(100vh - var(--h-height) - var(--f-height) - 0.4em - var(--textarea-height));
        }

        @media (max-width: $bp-lg) {
            padding: 1em 1.5em;
            height: calc(100vh - var(--h-height) - var(--f-height) - 1em - var(--textarea-height));

            &.system {
                height: calc(100vh - var(--h-height) - var(--f-height)) !important;
            }
        }

        @media (max-width: $bp-md) {
            padding: 1em;
        }

        @media (max-width: $bp-sm) {
            padding: 1em 0.5em;
            height: calc(100vh - var(--h-height) - var(--f-height) - 2em);
        }


        &-message {
            @include flex(row, flex-start, flex-start);
            margin-bottom: 1.5em;

            img {
                width: 3.34em;
                height: 3.34em;
                border-radius: 50%;
                position: relative;
                z-index: 10;
                margin-right: 1em;
            }

            .text {
                flex: 1;
                @include flex(column, flex-start, flex-start, nowrap);
                white-space: pre-wrap;

                .bubble {
                    position: relative;
                    width: fit-content;
                    background: var(--mess-user);
                    border-radius: $radius;
                    padding: 1em;
                    font-weight: 400;
                    overflow-wrap: anywhere;
                    font-size: 0.9em;
                    text-align: left;

                    &-img {
                        max-height: 228px;
                        max-width: 304px;
                        height: 228px;
                        width: 100%;
                        object-fit: cover;
                        border-radius: 3%;
                        cursor: pointer;
                        margin-left: 0px;
                        margin-right: 0em;
                        margin-top: 1em;
                    }

                    &-admin {
                        position: relative;
                        width: fit-content;
                        min-width: 50%;
                        background: var(--mess-admin);
                        border-radius: $radius;
                        padding: 1em;
                        font-weight: 400;
                        overflow-wrap: anywhere;
                        font-size: 0.9em;
                        text-align: left;
                    }

                    &-system {
                        position: relative;
                        width: fit-content;
                        min-width: 50%;
                        background: var(--mess-system);
                        border-radius: $radius;
                        padding: 1em;
                        font-weight: 400;
                        overflow-wrap: anywhere;
                        font-size: 0.9em;
                        text-align: center;
                        white-space: normal;

                        .system-border {
                            border: 1px solid var(--mess-system-border);
                            padding: 1em;
                            border-radius: $radius;
                        }

                        &-img {
                            height: auto;
                            width: 150px;
                            color: #eee !important;
                            border-radius: 0%;
                        }
                    }

                    &-ban {
                        position: relative;
                        width: fit-content;
                        min-width: 50%;
                        background: var(--mess-ban);
                        border-radius: $radius;
                        padding: 1em;
                        font-weight: 400;
                        overflow-wrap: anywhere;
                        font-size: 0.9em;
                        text-align: left;
                        color: white;
                    }
                }
            }

            &-mine {
                @extend .chat-window-message;

                img {
                    margin-left: 1em;
                    margin-right: 1em;
                }

                .text {
                    align-items: flex-end;
                    white-space: pre-wrap;

                    .bubble {
                        overflow-wrap: anywhere;
                        background: var(--mess-mine);
                        text-align: right;

                        &-admin {
                            overflow-wrap: anywhere;
                            background: var(--mess-admin);
                            text-align: right;
                        }

                        &-system {
                            overflow-wrap: anywhere;
                            background: var(--mess-system);
                            text-align: center;
                            white-space: normal;

                            .system-border {
                                border: 1px solid var(--mess-system-border);
                                padding: 1em;
                                border-radius: $radius;
                            }

                            &-img {
                                height: auto;
                                width: 150px;
                                color: var(--gray) !important;

                                border-radius: 0%;
                            }
                        }

                        &-ban {
                            background: var(--mess-ban);
                            color: white;
                            overflow-wrap: anywhere;
                            text-align: right;
                        }

                        &-img {
                            margin-left: 0px;
                            margin-right: 0em;
                            margin-top: 1em;
                            cursor: pointer;
                            max-height: 228px;
                            max-width: 304px;
                            height: 228px;
                            width: 100%;
                            object-fit: cover;
                            object-position: center;
                            border-radius: 3%;
                        }
                    }
                }
            }
        }
    }



    &-form {
        @include flex(row, flex-start, center, nowrap);
        border-top: 1px solid #9DC4EF4D;


        textarea {
            border: none;
            background-color: transparent;
            resize: none;

            &::-webkit-scrollbar {
                display: none;
            }


        }

        .input-file-simple {
            padding-right: 0;
        }

        button {
            background: var(--btn-1);
            margin-left: 1.25em;
            padding: .9em 1.4em;
            font-size: .9em;
            height: fit-content;
            color: white;
            transition: $transition;
            transition-property: background-color, color;
            border-radius: 8px;
            border: 1px solid transparent;

            &:hover {
                border: 1px solid var(--param-hover);
            }

            &:active {
                background: var(--param-active);
            }
        }
    }
}

.sec-chat {
    position: relative;
    box-shadow: 0px 4px 15px 0px rgba(100, 100, 100, 0.08);
    background: var(--label);


    &-top {
        background: var(--glass);
        box-shadow: var(--shadow);
        border-radius: 10px 10px 0px 0px;
        border-bottom: 1px solid #9DC4EF4D;
        @include flex(row, flex-start, center, nowrap);
        padding: 1em;

        h2 {
            font-size: 2.85em;
            margin-left: 1em;
            margin-bottom: 0px;
        }

        &-count {
            width: 6em;
            height: 6em;
            border-radius: 50%;
            background-color: var(--count);
            color: white;
            text-align: center;
            @include flex(column, center, center, nowrap);

            .num {
                font-size: 1.34em;
            }

            .text {
                font-size: .86em;
            }
        }
    }

    .chat {
        border-top: none;
        border-radius: 0px 0px 10px 10px;


        &-window {
            height: calc(100vh - var(--h-height) - 13em - var(--textarea-height));
            max-height: calc(100vh - var(--h-height) - 15em);

        }
    }
}

.sec-messages-chat {
    box-shadow: 0px 4px 15px 0px rgba(100, 100, 100, 0.08);
}

/* Popular products */
.sec-popular {
    h2 {
        margin-bottom: 0.1em;
        padding: 0 0.35em;
        font-size: 2.85em;

        @media (max-width: $bp-xl) {
            font-size: 2.65em;
        }
    }

    .offers-slider {
        height: 785px;
        padding: 0 1.5em 0 1em;
        margin-right: -0.5em;

        .swiper-scrollbar {
            right: 0.5em;
            background-color: #fff;
            box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8),
                1px 3px 20px rgba(100, 100, 100, 0.15);
            backdrop-filter: blur(4px);
            width: 5px;

            &-drag {
                background-color: var(--pale-blue);
            }
        }

        .swiper-slide {
            height: fit-content;
        }

        .offer-card {
            background: var(--glass);
            box-shadow: var(--shadow);
            backdrop-filter: blur(5px);
            border-radius: $radius;
            padding: 1.6em 1.6em 0.7em 1.6em;

            &-top {
                @include flex(row, space-between, center, nowrap);

                h5 {
                    font-weight: 700;
                    color: var(--green);

                    h5 {
                        @include clamp(1, 1.4em, true);
                    }
                }
            }

            &-main {
                margin-top: 1em;
                @include flex(row, space-between, flex-start, nowrap);

                h5 {
                    @include clamp(2, 1.4em, true);
                }
            }

            &-text {
                margin-top: 1em;

                p {
                    font-weight: 300;
                    @include clamp(2, 1.4em);
                }
            }

            &-bottom {
                margin-top: 1em;
                @include flex(row, space-between, center, nowrap);
            }
        }
    }
}



/* Game page */
.page-game {
    overflow: hidden;
    padding-left: 3em;
    padding-right: 3em;

    &-top {
        h1 {
            --w: 150px;
            text-align: center;
            position: relative;
            width: fit-content;
            margin: 0em auto .25em;
            padding: 0em .6em;

            &::before {
                content: "";
                display: block;
                position: absolute;
                right: 100%;
                top: 50%;
                width: var(--w);
                height: 1px;
                background-color: currentColor;
            }

            &::after {
                content: "";
                display: block;
                position: absolute;
                left: 100%;
                top: 50%;
                width: var(--w);
                height: 1px;
                background-color: currentColor;
            }

            @media (max-width: $bp-md) {
                --w: 85px;
            }

            @media (max-width: $bp-sm) {
                --w: 50px;
            }
        }

        .categories {
            position: unset;
            @include list();
            @include flex(row, flex-start, center, wrap);
            margin-top: 1em;
            margin-bottom: 2em;

            @media (max-width: $bp-md) {
                margin-bottom: 1em !important;
            }

            @media (max-width: $bp-xl) {
                margin-bottom: 0;
            }

            li {
                font-size: 1.2em;
                margin-right: 1.1em;
                margin-bottom: 1.1em;

                @media (max-width: $bp-xl) {
                    margin-bottom: 2em;
                    position: relative;
                    z-index: 10;
                }

                @media (max-width: $bp-md) {
                    font-size: 1em;
                }

                .button,
                a {
                    background: var(--param);
                    padding: 0.55em;
                    @include boxShadow();
                    border-radius: $radius * 0.5;
                    transition: $transition;
                    color: var(--font-color);
                    border: 1px solid transparent;

                    &:hover {
                        border: 1px solid var(--param-hover);
                    }

                    &:active,
                    &.active {
                        background: var(--param-active);
                        color: white;

                    }

                    @media (max-width: $bp-xl) {

                        &:hover {
                            border: 1px solid var(--param-hover);
                        }

                        &:active,
                        &.active {
                            background: var(--param-active);
                            color: white;
                        }
                    }
                }
            }
        }

        .filter {
            position: relative;
            z-index: 10;
            @include flex(row, flex-start, center, wrap);
            gap: 1em;

            @media (max-width: $bp-xl) {
                background: var(--glass);
                padding: 1em;
                border-radius: $radius;
                @include shadowMobile();
            }

            &-top {
                width: 100%;
                @include flex(row-reverse, flex-end, center, wrap);
                gap: 1em;

                input[type="search"] {
                    width: 340px;
                    border-color: var(--search-game-border);
                    background: var(--glass);
                    @include placeholder(var(--search-game-text));

                    @media (max-width: $bp-xxl) {
                        width: 270px;
                    }

                    @media (max-width: $bp-md) {
                        width: 100%;
                    }
                }
            }

            input:not([type="checkbox"]) {
                border-color: var(--pale-blue);
                @include placeholder(var(--search-game-text));
            }

            input[type="number"] {
                width: 70px;
                padding: 0.7em 0.75em;
                text-align: center;
            }

            .dropdown {
                .select {
                    background: var(--glass);
                    padding: 0.7em 1em;
                    border-radius: $radius;
                    box-shadow: var(--shadow);
                    border: 1px solid transparent;

                    &:hover {
                        border: 1px solid var(--param-hover);
                    }

                    &:active,
                    &-active {
                        background: var(--param-active);
                        color: white;

                    }



                }



                .dropdown-item {
                    display: block !important;
                    width: 100%;

                    &:active,
                    &.active {
                        background: var(--param-active);
                        color: white;

                    }
                }


            }
        }
    }

    &-offers {
        background: var(--glass);
        box-shadow: var(--shadow);
        backdrop-filter: blur(5px);
        border-radius: $radius;
        --pad: 15px 20px;
        --serv: 220px;
        --seller: 220px;
        --availability: 165px;
        --price: 140px;

        @media (max-width: $bp-xl) {
            background: transparent;
            border-radius: 0px;
            box-shadow: none;
            --pad: 0px 1em;
        }

        @media (max-width: $bp-md) {
            --pad: 0px 0.5em;
            --price: 90px;
        }

        .top {
            background-color: var(--extrapale-gray);
            border-radius: $radius $radius 0px 0px;
            padding: 0 2em;
            display: grid;
            grid-template-columns: var(--serv) auto var(--seller) var(--availability) var(--price);
            grid-template-rows: 1fr;
            grid-template-areas: "serv descr seller availability price";

            @media (max-width: $bp-xl) {
                display: none;
            }

            div {
                font-size: 1.2em;
                font-weight: 600;
                padding: var(--pad);

                &.serv {
                    grid-area: serv;
                }

                &.descr {
                    grid-area: descr;
                }

                &.seller {
                    grid-area: seller;
                }

                &.availability {
                    grid-area: availability;
                    text-align: center;
                }

                &.price {
                    grid-area: price;
                    text-align: center;
                }
            }
        }

        ul {
            @include list();
            padding: 2em;

            @media (max-width: $bp-xl) {
                padding: 0px;
            }
        }
    }

    &-offers-no {
        background: var(--glass);
        box-shadow: var(--shadow);
        backdrop-filter: blur(5px);
        border-radius: $radius;
        --pad: 15px 20px;
        --seller: 220px;
        --availability: 165px;
        --price: 140px;

        @media (max-width: $bp-xl) {
            background: transparent;
            border-radius: 0px;
            box-shadow: none;
            --pad: 0px 1em;
        }

        @media (max-width: $bp-md) {
            --pad: 0px 0.5em;
            --price: 90px;
        }

        .top {
            background-color: var(--extrapale-gray);
            border-radius: $radius $radius 0px 0px;
            padding: 0 2em;
            display: grid;
            grid-template-columns: auto var(--seller) var(--availability) var(--price);
            grid-template-rows: 1fr;
            grid-template-areas: "descr seller availability price";

            @media (max-width: $bp-xl) {
                display: none;
            }

            div {
                font-size: 1.2em;
                font-weight: 600;
                padding: var(--pad);

                &.descr {
                    grid-area: descr;
                }

                &.seller {
                    grid-area: seller;
                }

                &.availability {
                    grid-area: availability;
                    text-align: center;
                }

                &.price {
                    grid-area: price;
                    text-align: center;
                }
            }
        }


        ul {
            @include list();
            padding: 2em;

            @media (max-width: $bp-xl) {
                padding: 0px;
            }
        }
    }
}

:root[data-theme='light'] .page-game {
    background: url(imgs/bg/gradient.jpg) no-repeat center bottom;
    background-size: 100% 65%;
}

.offer-line {
    display: grid;
    grid-template-columns: var(--serv) auto var(--seller) var(--availability) var(--price);
    grid-template-rows: 1fr;
    grid-template-areas: "serv descr seller availability price";
    background-color: var(--letter);
    @include boxShadow();
    border-radius: $radius;

    @media (max-width: $bp-xl) {
        grid-template-columns: auto var(--price);
        grid-template-rows: 2em auto 3em;
        grid-template-areas: "serv serv price"
            "descr descr descr"
            "seller seller availability";
        gap: 10px 0px;
        padding: 1em 0;
        background: var(--glass);
    }

    @media (max-width: $bp-md) {
        padding: 0.5em 0;
    }


    .serv {
        grid-column: span 1;
        grid-row: span 1;
        padding: var(--pad);
        grid-area: serv;

        @media (max-width: $bp-xl) {
            color: var(--blue);
            @include flex(row, flex-start, center, nowrap);
        }
    }

    .descr {
        grid-column: span 1;
        grid-row: span 1;
        padding: var(--pad);
        grid-area: descr;
        @include clamp(3, 1.2em, true);

        @media (max-width: $bp-xl) {
            @include clamp(2.5, 1.2em, true);
        }
    }

    .seller {
        grid-column: span 1;
        grid-row: span 1;
        padding: var(--pad);
        grid-area: seller;
        @include flex(row, flex-start);

        @media (max-width: $bp-xl) and (min-width: $bp-sm) {
            font-size: 0.8em;
        }

        img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 50%;
            margin-right: 0.75em;

            @media (max-width: $bp-xl) {
                width: 40px;
                height: 40px;
            }
        }
    }

    .availability {
        grid-column: span 1;
        grid-row: span 1;
        padding: var(--pad);
        grid-area: availability;
        text-align: center;

        @media (max-width: $bp-xl) {
            @include flex(row, flex-end, center, nowrap);
            text-align: right;
        }
    }

    .price {
        grid-column: span 1;
        grid-row: span 1;
        padding: var(--pad);
        grid-area: price;
        text-align: center;

        @media (max-width: $bp-xl) {
            @include flex(row, flex-end, center, nowrap);
            text-align: right;
            font-weight: 600;
        }
    }
}

.offer-line-no {
    display: grid;
    grid-template-columns: auto var(--seller) var(--availability) var(--price);
    grid-template-rows: 1fr;
    grid-template-areas: "descr seller availability price";
    background-color: var(--letter);
    @include boxShadow();
    border-radius: $radius;

    @media (max-width: $bp-xl) {
        grid-template-columns: auto var(--price);
        grid-template-rows: 2em auto 3em;
        grid-template-areas: "price price price"
            "descr descr descr"
            "seller seller availability";
        gap: 10px 0px;
        padding: 1em 0;
        background: var(--glass);
    }

    @media (max-width: $bp-md) {
        padding: 0.5em 0;
    }


    .descr {
        grid-column: span 1;
        grid-row: span 1;
        padding: var(--pad);
        grid-area: descr;
        @include clamp(3, 1.2em, true);

        @media (max-width: $bp-xl) {
            @include clamp(2.5, 1.2em, true);
        }
    }

    .seller {
        grid-column: span 1;
        grid-row: span 1;
        padding: var(--pad);
        grid-area: seller;
        @include flex(row, flex-start);

        @media (max-width: $bp-xl) and (min-width: $bp-sm) {
            font-size: 0.8em;
        }

        img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 50%;
            margin-right: 0.75em;

            @media (max-width: $bp-xl) {
                width: 40px;
                height: 40px;
            }
        }
    }

    .availability {
        grid-column: span 1;
        grid-row: span 1;
        padding: var(--pad);
        grid-area: availability;
        text-align: center;

        @media (max-width: $bp-xl) {
            @include flex(row, flex-end, center, nowrap);
            text-align: right;
        }
    }

    .price {
        grid-column: span 1;
        grid-row: span 1;
        padding: var(--pad);
        grid-area: price;
        text-align: center;

        @media (max-width: $bp-xl) {
            @include flex(row, flex-end, center, nowrap);
            text-align: right;
            font-weight: 600;
        }
    }
}

/* Trader page */
.trader-line {
    display: grid;
    grid-template-columns: 160px 160px auto 120px 120px;
    grid-template-rows: 1fr;
    grid-template-areas: "category serv title count price";
    background-color: var(--letter);
    @include boxShadow();
    border-radius: $radius;
    border-bottom: none;

    // @media (max-width: $bp-xl) {
    //   grid-template-columns:auto var(--price);
    //   grid-template-rows: 2em auto 3em;
    //   grid-template-areas:
    //   "serv price"
    //   "descr descr"
    //   "seller availability";
    //   gap: 10px 0px;
    //   padding: 1em 0;
    // }

    padding: 1em;


    &>a {
        &>div {
            grid-column: span 1;
            grid-row: span 1;
        }

    }

    .category {
        grid-area: category;
        align-items: center;
        @include clamp(1, 1.4em, true);
        width: 160px;
    }

    .server {
        grid-area: serv;
        @include clamp(1, 1.4em, true);
        width: 160px;
        color: var(--blue);
        // @media (max-width: $bp-xl) {
        //     color: var(--blue);
        //     @include flex(row, flex-start, center, nowrap);
        // }
    }

    .count {
        grid-area: count;
        text-align: right;
        width: 120px;
        @include clamp(1, 1.4em, true);
    }


    .title {
        grid-area: title;
        text-align: left;
        width: 100%;
        @include clamp(1, 1.4em, true);
    }

    .price {
        grid-area: price;
        text-align: right;
        width: 120px;

        @media (max-width: $bp-xl) {
            @include flex(row, flex-end, center, nowrap);
            text-align: right;
            font-weight: 600;
        }
    }
}

/* Lot page */
.lot-page {
    padding-left: 3em;
    padding-right: 3em;

    .specifications {
        // @include list();
        font-size: 0.9em;

        li {
            @include flex(row, space-between, center, nowrap);
            position: relative;

            &::after {
                content: "";
                position: absolute;
                bottom: 0.2em;
                left: 0em;
                width: 100%;
                height: 1px;
                background-color: var(--extrapale-line);
            }

            // &:not(:first-child) {
            margin-top: 0.5em;
            // }

            span {
                position: relative;
                z-index: 2;
                background-color: var(--body-bg);
                padding: 0em 0.5em;
                line-height: normal;
                @include textEllipsis();
                width: fit-content;
                max-width: 48%;

                &:first-child {
                    color: var(--gray);
                }

                &:last-child {
                    color: var(--blue);
                }
            }
        }
    }

    &-box {
        border: 1px solid var(--extrapale-line);
        border-radius: $radius;

        hr {
            background-color: var(--extrapale-line);
            width: 100%;
            height: 1px;
            border: none;
            margin: 0px;
            opacity: 1;
        }

        .seller {
            @include flex(row, flex-start, center, nowrap);

            img {
                @include roundedImg(70px, 50%, cover);
                margin-right: 1em;
            }

            .rating {
                font-size: 1.2em;
                font-weight: 700;
                @include flex();

                svg {
                    color: var(--blue);
                    font-size: 1.1em;

                    path {
                        fill: var(--blue);
                    }
                }
            }
        }

        .chat {
            border: none;

            &-window {
                height: calc(100vh - var(--h-height) - 20em);

            }
        }
    }

    &-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 50px auto auto auto auto;
        gap: 0px 0px;
        grid-template-areas: "game info info date"
            "title title title status"
            "payment payment payment payment"
            "specifications specifications specifications specifications"
            "text text text text";

        @media (max-width: $bp-md) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 50px auto auto auto auto auto auto;
            grid-template-areas: "game date"
                "info info"
                "title title"
                "status status"
                "specifications specifications"
                "text text"
                "payment payment";
        }

        // @media (max-width: $bp-sm) {
        //   grid-template-columns: 100px auto;
        // }

        .game {
            grid-area: game;
            width: 100%;
            padding: 0em 1em;
            @include flex(column, center, flex-start, nowrap);

            @media (max-width: $bp-md) {
                padding: 1em;
            }

            img {
                @include roundedImg(50px, 50%, cover);
            }
        }

        .date {
            grid-area: date;
            @include flex(row, flex-end, center, nowrap);
            padding: 0px 1em;
            color: var(--light-gray);

            @media (max-width: $bp-sm) {
                align-items: center;
            }

            time {
                font-size: 0.8em;
            }
        }

        .info {
            grid-area: info;
            @include flex(row, flex-start, center, wrap);

            @media (max-width: $bp-md) {
                padding: 1em;
                border-top: 1px solid var(--extrapale-line);
            }

            .tag-gray {
                background-color: var(--extrapale-gray);
                padding: 0.25em 0.5em;
                border-radius: 0.5em;
            }

            .tag-green {
                background-color: var(--green);
                color: var(--white);
                padding: 0.25em 0.5em;
                border-radius: 0.5em;
            }
        }

        .title {
            grid-area: title;
            color: var(--blue);
            padding: 1em;
            border-top: 1px solid var(--extrapale-line);
            border-bottom: 1px solid var(--extrapale-line);
        }

        .status {
            grid-area: status;
            color: var(--mess-ban);
            font-weight: bold;
            text-align: end;
            padding: 1em;
            border-top: 1px solid var(--extrapale-line);
            border-bottom: 1px solid var(--extrapale-line);
        }

        .payment {
            grid-area: payment;
            @include flex(row, flex-start, flex-end, wrap);
            gap: 1em;
            padding: 1em;
            border-bottom: 1px solid var(--extrapale-line);



            .labeled-input {
                width: 100px;

                &.nickname {
                    width: auto !important;
                }

                label {
                    padding: 0 0.35em;
                    font-size: .8em;
                    transform: translateY(-50%);
                }
            }
        }

        .text {
            grid-area: text;
            padding: 1em;
            @include flex(row, flex-start, center, nowrap);

            @media (max-width: $bp-md) {
                border-bottom: 1px solid var(--extrapale-line);
                font-size: .9em;
            }
        }

        .specifications {
            grid-area: specifications;
            padding: 1em;
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            gap: 0 2em;

            @media (max-width: $bp-md) {
                font-size: .9em;
                grid-template-columns: 1fr;
            }
        }

    }

    .dropdown {
        .select {
            background-color: var(--body-bg);
            padding: 0.7em 1em;
            border-radius: $radius;
            border: 1px solid $light-gray;
        }
    }
}

/* Order page */
.order-page {
    padding-left: 3em;
    padding-right: 3em;

    .specifications {
        // @include list();
        font-size: 0.9em;

        li {
            @include flex(row, space-between, center, nowrap);
            position: relative;

            &::after {
                content: "";
                position: absolute;
                bottom: 0.2em;
                left: 0em;
                width: 100%;
                height: 1px;
                background-color: var(--extrapale-line);
            }

            // &:not(:first-child) {
            margin-top: 0.5em;
            // }

            span {
                position: relative;
                z-index: 2;
                background-color: var(--body-bg);
                padding: 0em 0.5em;
                line-height: normal;
                @include textEllipsis();
                width: fit-content;
                max-width: 48%;

                &:first-child {
                    color: var(--gray);
                }

                &:last-child {
                    color: var(--blue);
                }
            }
        }
    }

    &-box {
        border: 1px solid var(--extrapale-line);
        border-radius: $radius;

        hr {
            background-color: var(--extrapale-line);
            width: 100%;
            height: 1px;
            border: none;
            margin: 0px;
            opacity: 1;
        }

        .seller {
            @include flex(row, flex-start, center, nowrap);

            img {
                @include roundedImg(70px, 50%, cover);
                margin-right: 1em;
            }

            .rating {
                font-size: 1.2em;
                font-weight: 700;
                @include flex();

                svg {
                    color: var(--blue);
                    font-size: 1.1em;

                    path {
                        fill: var(--blue);
                    }
                }
            }
        }

        .chat {
            border: none;

            &-window {
                height: calc(100vh - var(--h-height) - 20em);

            }
        }
    }

    &-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 50px auto auto auto auto auto;
        gap: 0px 0px;
        grid-template-areas: "game info info info"
            "title title title title"
            "payment payment status status"
            "specifications specifications specifications specifications"
            "text text text text"
            "date date date date";

        @media (max-width: $bp-md) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 50px auto auto auto auto auto auto auto;
            grid-template-areas: "game game"
                "info info"
                "title title"
                "payment payment"
                "status status"
                "specifications specifications"
                "text text"
                "date date";
        }

        // @media (max-width: $bp-sm) {
        //   grid-template-columns: 100px auto;
        // }

        .game {
            grid-area: game;
            width: 100%;
            padding: 0em 1em;
            @include flex(column, center, flex-start, nowrap);

            @media (max-width: $bp-md) {
                padding: 1em;
            }

            img {
                @include roundedImg(50px, 50%, cover);
            }
        }

        .date {
            grid-area: date;
            @include flex(row, space-between, flex-end, wrap);
            gap: 1em;
            padding: 1em;

            border-top: 1px solid var(--extrapale-line);

            @media (max-width: $bp-sm) {
                align-items: center;
            }

            div {
                @include flex(row, flex-start, flex-end, wrap);
                gap: 1em;
            }

            time {
                color: var(--light-gray);
                font-size: 0.8em;
            }
        }

        .info {
            grid-area: info;
            @include flex(row, flex-start, center, wrap);

            @media (max-width: $bp-md) {
                padding: 1em;
                border-top: 1px solid var(--extrapale-line);
            }

            .tag-gray {
                background-color: var(--extrapale-gray);
                padding: 0.25em 0.5em;
                border-radius: 0.5em;
            }

            .tag-green {
                background-color: var(--green);
                color: var(--white);
                padding: 0.25em 0.5em;
                border-radius: 0.5em;
            }
        }

        .title {
            grid-area: title;
            color: var(--blue);
            padding: 1em;
            border-top: 1px solid var(--extrapale-line);
        }

        .status {
            grid-area: status;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            // padding: 1em;
            border-top: 1px solid var(--extrapale-line);
            gap: 1;

            .price {
                border: 1px solid var(--light-blue);
                color: var(--light-blue);
                background-size: 200% 100% !important;
                padding: .8em 1.6em;
                border-radius: .67em;
            }


        }

        .payment {
            grid-area: payment;
            @include flex(row, flex-start, flex-end, wrap);
            gap: 1em;
            padding: 1em;
            border-top: 1px solid var(--extrapale-line);

        }

        .text {
            grid-area: text;
            padding: 1em;
            @include flex(row, flex-start, center, nowrap);

            @media (max-width: $bp-md) {
                border-top: 1px solid var(--extrapale-line);
                font-size: .9em;
            }
        }

        .specifications {
            grid-area: specifications;
            padding: 1em;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            border-top: 1px solid var(--extrapale-line);
            gap: 0 2em;

            @media (max-width: $bp-md) {
                font-size: .9em;
                grid-template-columns: 1fr;
            }
        }

    }

    .dropdown {
        .select {
            background-color: var(--body-bg);
            padding: 0.7em 1em;
            border-radius: $radius;
            border: 1px solid $light-gray;
        }
    }
}

.review-card {
    border: 1px solid var(--extrapale-line);

    background: var(--review-color);
    border-radius: $radius;
    padding: 0.75em;

    .user {
        @include flex(row, flex-start, center, nowrap);

        @media (max-width: $bp-xxl) {
            flex: 1;
        }

        &-photo {
            @include roundedImg(30px, 50%, cover);
            border: none;
            margin-right: 0.5em;
        }
    }

    time {
        font-size: 0.8em;
        color: var(--pale-blue);
    }

    .rating {
        font-size: 1.2em;
        font-weight: 700;
        @include flex();

        svg {
            color: var(--blue);
            font-size: 1.1em;

            path {
                fill: var(--blue);
            }
        }
    }



    p {
        cursor: pointer;
        @include clamp(3, 1.3em, false);
        overflow-wrap: anywhere;
    }

    .clamp-off {
        @include clamp(910, 1.3em, false);
    }
}

/* Registration page */
.sec-registration {
    margin-top: 4em;

    @media (max-width: $bp-lg) {
        margin-top: 0em;
    }

    form {
        &.midi {
            width: 530px;

            @media (max-width: $bp-xxl) {
                width: 480px;
            }

            @media (max-width: $bp-lg) {
                width: 100%;
            }
        }

        &.mini {
            width: 440px;

            @media (max-width: $bp-xxl) {
                width: 330px;
            }

            @media (max-width: $bp-lg) {
                width: 100%;
                margin-top: 4em;
            }
        }
    }
}

/* Entering page */
.sec-login {
    .wrap {
        background: var(--second-bg);
        box-shadow: var(--shadow);
        backdrop-filter: blur(5px);
        padding: 2em 2em 1.5em 2em;
        border-radius: $radius;
        height: 100%;

        @media (max-width: $bp-sm) {
            padding: 1.5em 1.5em 1em 1.5em;
        }

        .labeled-input {
            --bg: var(--second-bg);
        }
    }

    .cover {
        border-radius: $radius;
        @include bgTextureGradient(linear-gradient(115deg, var(--black) 0%, var(--blue) 55%, var(--total-black) 100%));
        color: var(--white);
        height: 395px;
        padding: 0 5em;
        @include flex(row, flex-start, center, nowrap);
        position: relative;
        overflow: hidden;
        @include safariFix();

        @media (max-width: $bp-xxl) {
            height: 412px;
            padding: 0 3em;
        }

        @media (max-width: $bp-lg) {
            height: 150px;
            overflow: visible;
            margin-bottom: 2em;
        }

        @media (max-width: $bp-sm) {
            height: 100px;
            padding: 0 1em;
            margin-bottom: 1em;
        }

        h2 {
            line-height: 100%;
        }

        img {
            position: absolute;
            right: 0%;
            bottom: 0%;
            height: 85%;
            z-index: 10;

            @media (max-width: $bp-xxl) {
                height: 70%;
            }

            @media (max-width: $bp-lg) {
                height: 110%;
                right: 15%;
            }

            @media (max-width: $bp-md) {
                right: 5%;
            }

            @media (max-width: $bp-sm) {
                height: 100%;
            }
        }
    }
}

/* Password restoration page */
.sec-password {
    .wrap {
        background: var(--second-bg);
        box-shadow: var(--shadow);
        backdrop-filter: blur(5px);
        padding: 2em 2em 1.5em 2em;
        border-radius: $radius;

        @media (max-width: $bp-sm) {
            padding: 1.5em 1.5em 1em 1.5em;
        }

        .labeled-input {
            --bg: var(--second-bg);
        }
    }
}

.sec-favorites {

    ul {
        @include list();
    }

    .fav-item {
        @include flex(row, flex-start, center, nowrap);
        background: var(--label);
        @include lightShadow();
        padding: 1.35em;

        border-radius: $radius;

        @media (max-width: $bp-xxl) {
            padding: 0.5em 1em;
        }

        img {
            @include rounedImg(50px);
            margin-right: 1em;
        }

        h5 {
            flex: 1;
            @include clamp(2, 1.2em, false);
        }

        button {
            @include flex();
            color: var(--gray);
            font-size: 1.35em;

            svg {
                stroke-width: 1px;
            }
        }
    }
}

/***********
ACCOUNT
***********/
.account {
    position: relative;
    overflow: hidden;

    .return-icon {
        display: flex;
        font-size: 2em;
        color: var(--blue);
    }

    @media (max-width: $bp-lg) {
        h1 {
            color: var(--blue);
            font-size: 1.5em;
        }
    }

    @media (max-width: $bp-sm) {
        h1 {
            font-size: 1.25em;
        }

        .return-icon {
            font-size: 1.6em;
            color: var(--blue);
        }
    }

    .account-bg {
        @include absBG(1, 60%, auto);
    }

    .container {
        position: relative;
        z-index: 2;
    }

    &-nav {
        &-balance {
            background-color: var(--blue);
            color: white;
            padding: 1em;
            border-radius: $radius;
            text-align: center;
            margin-bottom: 1em;

            div {
                @include flex();

                span {
                    font-family: $title-font;
                    font-size: 2em;
                    margin-right: 0.34em;
                    line-height: 90%;
                }

                svg {
                    font-size: 1.5em;
                    padding: 0.1em;
                    border: 1px solid var(--white);
                    border-radius: 50%;
                }
            }
        }

        ul {
            @include list();

            li {
                &:not(:first-child) {
                    margin-top: 1em;

                    @media (max-width: $bp-lg) {
                        margin-top: 0.5em;
                    }
                }

                a {
                    color: var(--gray);
                    @include flex(row, flex-start, center, nowrap);
                    padding: 0.67em 1em;
                    border-radius: $radius;
                    transition: $transition;

                    @media (max-width: $bp-lg) {
                        font-size: 1.2em;
                    }

                    svg {
                        font-size: 1.6em;
                    }

                    div {
                        margin-left: 0.5em;
                        flex: 1;
                    }

                    .badge {
                        background-color: var(--extrapale-gray);
                        padding: 0.34em;
                        border-radius: $radius;
                        color: var(--total-black);
                    }

                    &:hover,
                    &:focus {
                        color: var(--blue);
                    }

                    &.active {
                        background-color: var(--extrapale-gray);
                        color: var(--blue);
                    }
                }
            }
        }
    }

    .sec-profile {
        position: relative;

        .share-btn {
            font-size: 1.5em;
            color: var(--dark-blue);

            @media (max-width: $bp-md) {
                position: absolute;
                top: 0em;
                right: 0em;
            }
        }
    }

    .post {
        display: block;
        background-color: var(--extrapale-gray);
        border-radius: $radius;
        color: var(--total-black);
        text-align: center;
        width: 124px;
        padding-bottom: 0.2em;
        max-width: 100%;

        @media (max-width: $bp-xxl) {
            @include flex(row, flex-start, center, nowrap);
            width: 100%;
            text-align: left;
            padding: 0.3em 0.6em;
        }

        img {
            width: 110%;
            margin-top: -25%;

            @media (max-width: $bp-xxl) {
                width: 3em;
                margin-top: unset;
                margin-right: 0.5em;
            }
        }
    }

    .btn-add-offer {
        background-color: var(--pale-blue);
        padding: .8em;
        @include flex();
        border-radius: 10px;
        @include boxShadow();
        transition: $transition;

        &:hover,
        &:focus,
        &:active {
            background: var(--blue);
        }

        @media (max-width: $bp-md) {
            font-size: 4.5em;
        }

        @media (max-width: $bp-sm) {
            font-size: 3em;
        }
    }

    .add-offer {
        padding: 3em 2em;
        border-radius: $radius;
        background: var(--glass);
        box-shadow: var(--shadow);
        backdrop-filter: blur(5px);

        .labeled-input {
            --bg: var(--label);
            width: 100%;

            label {
                border-radius: $radius;
            }
        }


        .dropdown {
            .select {
                border-radius: 0.67em;
                width: 100%;
                border: 1px solid var(--light-gray);
                box-shadow: none;
                min-width: 150px;
                padding: 0.725em 1.65em;

                &-label {
                    border-radius: $radius;
                    position: absolute;
                    z-index: 10;
                    top: 0px;
                    left: 1em;
                    transform: translateY(-60%);
                    width: fit-content;
                    background: var(--label);
                    color: var(--gray);
                    padding: 0 0.67em;

                }

                &:hover,
                &:focus,
                &:active,
                &.active {
                    border: 1px solid var(--blue);

                    .select-label {
                        color: var(--blue) !important;
                    }
                }
            }

        }

        @media (max-width: $bp-sm) {
            padding: 1.5em;
        }
    }

    .sec-feedback {
        .user {
            width: 50%;

            @media (max-width: $bp-xl) {
                width: 75%;
            }

            @media (max-width: $bp-md) {
                width: 100%;
            }

            &-photo {
                width: 60px;
                height: 60px;
            }

            &-main {
                height: 72px;
                margin-left: -20px;
                padding-left: 30px;

                .title {
                    @include clamp(1, 1em, true);
                }
            }
        }

        .box {
            background-color: var(--white);
            @include lightShadow();
            border-radius: $radius;
            padding: 0.8em 1.3em;
        }

        .info-list {
            @include list();
            @include flex(row, flex-start, center, wrap);

            li {
                margin-right: 1.5em;
                margin-bottom: 1em;
                border-bottom: none;

                @media (max-width: $bp-sm) {
                    margin-right: 1em;
                    margin-bottom: 0.75em;
                }
            }
        }
    }

    .sec-finance {
        .balance {
            background-color: var(--blue);
            color: white;
            padding: 1em 1.2em;
            border-radius: $radius;
            @include flex();
        }
    }

    .sec-messages {
        position: relative;
        z-index: 1;
        @include flex(row, flex-start, stretch, wrap);
        background: var(--body-bg);
        border: 1px solid var(--extrapale-gray);
        box-shadow: 0px 4px 15px 0px rgba(100, 100, 100, 0.08);
        backdrop-filter: blur(4px);
        border-radius: $radius;
        overflow: hidden;
        @include safariFix();
        max-height: calc(100vh - var(--h-height));

        @media (max-width: $bp-xl) {
            max-height: calc(100vh - var(--f-height) - 1em);
        }


        &-list {
            width: 30%;
            border-right: 1px solid var(--extrapale-gray);
            max-height: calc(100vh - var(--h-height) - 3em);

            @media (max-width: $bp-xxl) {
                width: 35%;
            }

            @media (max-width: $bp-lg) {
                max-height: calc(100vh - var(--h-height) - var(--f-height) - 2em);
            }

            @media (max-width: $bp-xl) {
                width: 100%;
                border-right: none;
            }

            ul {
                height: calc(100vh - var(--h-height) - 1em);
                @include list();
                overflow-y: auto;

                .div {
                    height: calc(100vh - var(--h-height) - 1em);
                }

                @media (max-width: $bp-lg) {
                    height: calc(100vh - var(--h-height) - var(--f-height));
                }

                @media (max-width: $bp-sm) {
                    height: calc(100vh - var(--h-height) - var(--f-height));
                }
            }

            .general-chat {
                @include flex(row, flex-start, center, nowrap);
                padding: 0.75em 1.3em;

                .count {
                    background-color: var(--black);
                    color: var(--white);
                    @include rounedImg(4.8em);
                    @include flex(column, center, center, nowrap);
                    font-size: 10px;
                    text-align: center;
                    line-height: 100%;
                }

                h6 {
                    margin-left: 0.5em;
                    flex: 1;
                    text-align: left;
                }
            }
        }

        &-chat {
            width: 70%;
            position: relative;

            @media (max-width: $bp-xxl) {
                width: 65%;
            }

            @media (max-width: $bp-xl) {
                width: 100%;
            }

            &-top {
                @include flex(row, flex-start, center, nowrap);
                padding: 0.75em 1.5em;
                border-bottom: 1px solid var(--extrapale-blue);


            }

            .chat {
                border: none;
                z-index: 1;

            }
        }
    }

    .sec-callback {
        .box {
            background: var(--second-bg);
            border-radius: $radius;
            box-shadow: var(--shadow);
            backdrop-filter: blur(5px);
            padding: 3em 2em;

            @media (max-width: $bp-sm) {
                padding: 1.5em;
            }

            .labeled-input {
                --bg: var(--second-bg);
            }
        }
    }

    .sec-appeal {
        .top {
            background-color: var(--extrapale-gray);
            border-radius: $radius $radius 0 0;
            @include flex(row, space-between, center, nowrap);
            padding: 1.3em 2em;

            @media (max-width: $bp-sm) {
                padding: 1em 1.5em;
            }
        }

        .box {
            background: var(--second-bg);
            border-radius: 0 0 $radius $radius;
            box-shadow: var(--shadow);
            backdrop-filter: blur(5px);
            padding: 1.5em 2em;

            .chat-window {
                height: 550px;
            }
        }
    }

    .rouble {
        font-size: 1em;
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;
        border: 1px solid #fff;
        @include flex();
    }
}

[data-theme='dark'] .account {
    .account-bg {
        display: none;
    }
}



.trader {
    position: relative;

    .chat-container {
        position: fixed;
        top: calc(var(--h-height) + 1em);
        right: 5em;

        .chat {
            &-window {
                height: calc(100vh - var(--h-height) - var(--f-height) - 13em);
            }
        }

        @media (max-width: $bp-xxl) {
            position: unset;



        }
    }

    .user {
        flex: 1;
        position: relative;
        @include flex(row, flex-start, center, nowrap);
        @include lightShadow();

        @media (max-width: $bp-md) {
            flex-wrap: wrap;
        }


        &-photo-1 {
            width: 90px;
            height: 90px;
            position: relative;
            z-index: 2;
            border-radius: 100%;
            overflow: hidden;
            @include safariFix();
            border: 5px solid var(--body-bg);
            box-sizing: content-box;
            margin-bottom: 1.8em;

            @media (max-width: $bp-xxl) {
                width: 80px;
                height: 80px;
            }

            @media (max-width: $bp-md) {
                width: 70px;
                height: 70px;
            }

            @media (max-width: $bp-sm) {
                width: 60px;
                height: 60px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: relative;
                z-index: 0;
            }




            &:hover,
            &:focus {
                &::after {
                    content: "";
                    @include absBG(1, 50%, 50%);
                    background: linear-gradient(to bottom, transparent, var(--total-black) 50%);
                    background-size: cover;


                }

                label {
                    cursor: pointer;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 2;
                    bottom: 0.25em;
                    color: var(--white);
                    font-size: 1.34em;
                    transition: $transition;

                    &:hover {
                        color: var(--green) !important;
                    }
                }
            }











        }


        &-main-1 {
            position: relative;
            z-index: 1;
            height: 119px;
            flex: 1;
            margin-left: -60px;
            padding-left: 72px;
            @include flex(column, center, flex-start, nowrap);


            @media (min-width: $bp-md) {
                &::before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: 0px;
                    left: 0px;
                    height: 100%;
                    width: 70%;
                    background-color: var(--extrapale-gray);
                    border-radius: $radius 0 0 $radius;
                }

                &::after {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: 0px;
                    right: 0px;
                    height: 100%;
                    width: 50%;
                    background-color: var(--extrapale-gray);
                    transform: skewX(-25deg);
                    border-radius: $radius;
                }
            }

            @media (max-width: $bp-md) {
                background-color: var(--extrapale-gray);
                border-radius: $radius;
                padding-left: 65px;
                height: 120px;
            }

            @media (max-width: $bp-sm) {
                height: 100px;
            }

            .title {
                font-family: $title-font;
                font-size: 2em;
                line-height: 100%;
                @include clamp(2, 1em);
            }



        }

        .status {
            width: 100%;
            margin-top: .5em;
            margin-left: -3.8em;

            .online {
                display: flex;
                align-items: center;
                width: 102%;

                @media (max-width: $bp-md) {
                    width: 110%;
                }

                &:before {

                    content: "";
                    display: inline-block;
                    width: 100%;
                    border-radius: $radius;
                    height: 10px;
                    background: linear-gradient(120.98deg, #33C954 48.77%, #78D848 85.89%);

                    margin-right: 1em;
                }
            }

            .offline {
                display: flex;
                align-items: center;
                width: 102%;

                @media (max-width: $bp-md) {
                    width: 110%;
                }

                &:before {

                    content: "";
                    display: inline-block;
                    width: 100%;
                    border-radius: $radius;
                    height: 10px;
                    background: $gray;

                    margin-right: 1em;
                }
            }

            .was {
                display: flex;
                align-items: center;

                @media (max-width: $bp-md) {
                    width: 110%;
                }

                @media (max-width: $bp-sm) {
                    width: 114%;
                }

                width: 105%;


                &:before {

                    content: "";
                    display: inline-block;


                    width: calc(100% - 140px);
                    border-radius: $radius;
                    height: 10px;
                    background: $gray;

                    margin-right: 1em;


                }

            }
        }

        &-info {
            @include list();
            @include flex(column, flex-start, flex-end, nowrap);
            margin-left: -1em;
            width: 215px;

            @media (max-width: $bp-xxl) {
                width: 200px;
            }

            @media (max-width: $bp-md) {
                font-size: 0.9em;
                width: 100%;
                margin-left: 0em;
                margin-top: 1em;
                flex-direction: row;
                justify-content: center;
                gap: 1em;
            }

            li {
                position: relative;
                padding: 0.6em;
                height: 41px;
                color: var(--black);
                font-weight: 600;
                width: 100%;


                @include flex(row, space-between, center, nowrap);

                @media (min-width: $bp-md) {


                    &:first-child {
                        width: 79%;
                    }

                    &:nth-child(2) {
                        width: 89.5%;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        top: 0px;
                        left: 0px;
                        height: 100%;
                        width: 94%;
                        background: var(--label);
                        transform: skewX(-25deg);
                        border-radius: $radius;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        top: 0px;
                        right: 0px;
                        height: 100%;
                        width: 89%;

                        background: var(--label);
                        border-radius: 0 $radius $radius 0;
                    }
                }

                @media (max-width: $bp-md) {
                    background: var(--glass);
                    box-shadow: var(--shadow);
                    border-radius: $radius;
                    flex-direction: column;
                    height: auto;
                    width: 23%;
                }

                div {
                    @include flex();

                    @media (max-width: $bp-md) {
                        flex-direction: column;
                    }

                    svg {
                        font-size: 1.6em;
                        margin-right: 0.34em;

                        @media (max-width: $bp-md) {
                            color: var(--blue);
                        }

                        &.svg {
                            stroke-width: 1.3px;
                        }

                        &.path {
                            path {
                                stroke-width: 1.3px;
                            }
                        }
                    }
                }

                &:not(:first-child) {
                    margin-top: 11px;
                }
            }
        }

        .qr-code {
            display: block;
            width: 100px;
            align-self: flex-start;

            @media (max-width: $bp-xl) {
                width: 80px;
            }

            @media (max-width: $bp-md) {
                position: absolute;
                top: 1em;
                right: 1em;
                z-index: 10;
            }

            @media (max-width: $bp-sm) {
                width: 50px;
            }
        }
    }
}

.user {
    position: relative;
    @include flex(row, flex-start, center, nowrap);
    @include lightShadow();

    @media (max-width: $bp-md) {
        flex-wrap: wrap;
    }


    &-photo {
        position: relative;
        z-index: 2;
        width: 110px;
        height: 110px;
        border-radius: 100%;
        overflow: hidden;
        @include safariFix();
        border: 5px solid var(--body-bg);
        box-sizing: content-box;



        @media (max-width: $bp-xxl) {
            width: 100px;
            height: 100px;
        }

        @media (max-width: $bp-md) {
            width: 90px;
            height: 90px;
        }

        @media (max-width: $bp-sm) {
            width: 70px;
            height: 70px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: relative;
            z-index: 0;
        }




        &:hover,
        &:focus {
            &::after {
                content: "";
                @include absBG(1, 50%, 50%);
                background: linear-gradient(to bottom, transparent, var(--total-black) 50%);
                background-size: cover;


            }

            label {
                cursor: pointer;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                z-index: 2;
                bottom: 0.25em;
                color: var(--white);
                font-size: 1.34em;
                transition: $transition;

                &:hover {
                    color: var(--green) !important;
                }
            }
        }




    }

    &-main {
        position: relative;
        z-index: 1;
        height: 144px;
        flex: 1;
        margin-left: -60px;
        padding-left: 72px;
        @include flex(column, center, flex-start, nowrap);


        @media (min-width: $bp-md) {
            &::before {
                content: "";
                position: absolute;
                z-index: -1;
                top: 0px;
                left: 0px;
                height: 100%;
                width: 70%;
                background-color: var(--extrapale-gray);
                border-radius: $radius 0 0 $radius;
            }

            &::after {
                content: "";
                position: absolute;
                z-index: -1;
                top: 0px;
                right: 0px;
                height: 100%;
                width: 50%;
                background-color: var(--extrapale-gray);
                transform: skewX(-25deg);
                border-radius: $radius;
            }
        }

        @media (max-width: $bp-md) {
            background-color: var(--extrapale-gray);
            border-radius: $radius;
            padding-left: 65px;
            height: 120px;
        }

        @media (max-width: $bp-sm) {
            height: 100px;
        }

        .title {
            font-family: $title-font;
            font-size: 2em;
            line-height: 100%;
            @include clamp(2, 1em);
        }



    }



    .status {
        width: 100%;
        margin-top: .5em;
        margin-left: -3.8em;

        .online {
            display: flex;
            align-items: center;
            width: 102%;

            @media (max-width: $bp-md) {
                width: 110%;
            }

            &:before {

                content: "";
                display: inline-block;
                width: 100%;
                border-radius: $radius;
                height: 10px;
                background: linear-gradient(120.98deg, #33C954 48.77%, #78D848 85.89%);

                margin-right: 1em;
            }
        }

        .offline {
            display: flex;
            align-items: center;
            width: 102%;

            @media (max-width: $bp-md) {
                width: 110%;
            }

            &:before {

                content: "";
                display: inline-block;
                width: 100%;
                border-radius: $radius;
                height: 10px;
                background: $gray;

                margin-right: 1em;
            }
        }

        .was {
            display: flex;
            align-items: center;

            @media (max-width: $bp-md) {
                width: 110%;
            }

            @media (max-width: $bp-sm) {
                width: 114%;
            }

            width: 105%;


            &:before {

                content: "";
                display: inline-block;


                width: calc(100% - 140px);
                border-radius: $radius;
                height: 10px;
                background: $gray;

                margin-right: 1em;


            }

        }
    }

    &-info {
        @include list();
        @include flex(column, flex-start, flex-end, nowrap);
        margin-left: -1em;
        width: 215px;

        @media (max-width: $bp-xxl) {
            width: 200px;
        }

        @media (max-width: $bp-md) {
            font-size: 0.9em;
            width: 100%;
            margin-left: 0em;
            margin-top: 1em;
            flex-direction: row;
            justify-content: center;
            gap: 1em;
        }

        li {
            position: relative;
            padding: 0.6em;
            height: 41px;
            color: var(--black);
            font-weight: 600;
            width: 100%;


            @include flex(row, space-between, center, nowrap);

            @media (min-width: $bp-md) {


                &:first-child {
                    width: 79%;
                }

                &:nth-child(2) {
                    width: 89.5%;
                }

                &::before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: 0px;
                    left: 0px;
                    height: 100%;
                    width: 94%;
                    background: var(--label);
                    transform: skewX(-25deg);
                    border-radius: $radius;
                }

                &::after {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: 0px;
                    right: 0px;
                    height: 100%;
                    width: 89%;

                    background: var(--label);
                    border-radius: 0 $radius $radius 0;
                }
            }

            @media (max-width: $bp-md) {
                background: var(--glass);
                box-shadow: var(--shadow);
                border-radius: $radius;
                flex-direction: column;
                height: auto;
                width: 23%;
            }

            div {
                @include flex();

                @media (max-width: $bp-md) {
                    flex-direction: column;
                }

                svg {
                    font-size: 1.6em;
                    margin-right: 0.34em;

                    @media (max-width: $bp-md) {
                        color: var(--blue);
                    }

                    &.svg {
                        stroke-width: 1.3px;
                    }

                    &.path {
                        path {
                            stroke-width: 1.3px;
                        }
                    }
                }
            }

            &:not(:first-child) {
                margin-top: 11px;
            }
        }
    }

    .qr-code {
        display: block;
        width: 100px;
        align-self: flex-start;

        @media (max-width: $bp-xl) {
            width: 80px;
        }

        @media (max-width: $bp-md) {
            position: absolute;
            top: 1em;
            right: 1em;
            z-index: 10;
        }

        @media (max-width: $bp-sm) {
            width: 50px;
        }
    }
}

.settings-line {
    padding: 1em;
    color: var(--total-black);
    display: grid;
    grid-template-columns: 150px 110px auto 140px 120px 60px;
    grid-template-rows: auto;
    grid-template-areas: "session system browser ip region btns";
    gap: 0.5em;

    // @media (max-width: $bp-xl) {
    //   flex-direction: column;
    //   align-items: stretch;
    // }
    @media (max-width: $bp-xl) {
        grid-template-columns: 1fr 1fr 80px;
        grid-template-areas: "session system btns"
            "browser browser browser"
            "ip ip region";
        border: 1px solid var(--pale-gray);
        border-radius: $radius;
    }

    @media (max-width: $bp-md) {
        font-size: 0.8em;
        gap: 0.25em;
        grid-template-columns: 1fr 1fr 60px;
    }

    @media (max-width: $bp-sm) {
        font-size: 1em;
    }

    .session {
        grid-area: session;
    }

    .system {
        grid-area: system;
        text-align: left;

        @media (max-width: $bp-xl) {
            text-align: right;
        }
    }

    .browser {
        grid-area: browser;
        text-align: center;

        @media (max-width: $bp-xl) {
            text-align: left;
        }
    }

    .ip {
        grid-area: ip;
        text-align: center;

        @media (max-width: $bp-xl) {
            text-align: left;
        }
    }

    .region {
        grid-area: region;
        text-align: center;

        @media (max-width: $bp-xl) {
            text-align: right;
        }
    }

    .btns {
        grid-area: btns;
        @include flex(row, flex-end, center, nowrap);

        button {
            @include flex();
            color: var(--blue);
            font-size: 1.35em;
            transition: $transition;

            &:hover,
            &:focus {
                color: var(--light-blue);
            }

            &:active {
                color: var(--dark-blue);
            }
        }
    }
}

.offer-line-2 {
    @include flex(row, space-between, center, nowrap);
    padding: 1em;
    color: var(--total-black);
    border-bottom: 1px solid var(--extrapale-blue);


    @media (max-width: $bp-xl) {
        flex-direction: column;
        align-items: stretch;
        border: 1px solid var(--pale-gray);
        border-radius: $radius;
    }

    .category {
        width: 150px;
        text-align: left;

        @media (max-width: $bp-xl) {
            flex: 1;
        }
    }

    .server {
        width: 200px;
        text-align: left;
        overflow-wrap: anywhere;
        @include clamp(1, 1.4em, false);

        @media (max-width: $bp-xl) {
            flex: 1;
        }
    }

    .title {
        width: 100%;
        text-align: left;
        overflow-wrap: anywhere;
        @include clamp(1, 1.4em, false);
    }

    .count {
        width: 120px;
        text-align: right;

        @media (max-width: $bp-xl) {
            flex: 1;
        }
    }

    .price {
        width: 120px;
        text-align: right;

        @media (max-width: $bp-xl) {
            flex: 1;
        }
    }

    .btns {
        width: 40px;
        @include flex(row, flex-end, center, nowrap);

        button {
            @include flex();
            color: var(--blue);
            font-size: 1.35em;
            transition: $transition;

            &:hover,
            &:focus {
                color: var(--light-blue);
            }

            &:active {
                color: var(--dark-blue);
            }
        }
    }
}

.offer-line-3 {
    padding: 1em;
    color: var(--total-black);
    display: grid;
    grid-template-columns: 145px 110px 1fr 200px 130px 80px;
    grid-template-rows: 1fr;
    grid-template-areas: "date id descr seller status price";
    border-bottom: 1px solid var(--extrapale-blue);

    @media (max-width: $bp-xxl) {
        grid-template-columns: 85px 90px 1fr 200px 130px 80px;
        font-size: 13px;
    }

    @media (max-width: $bp-xl) {
        border: 1px solid var(--pale-gray);
        border-radius: $radius;
        font-size: 14px;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: auto;
        gap: 0.5em;
        grid-template-areas: "descr descr"
            "id id"
            "status status"
            "seller date"
            "seller price";
    }

    @media (max-width: $bp-md) {
        font-size: 13px;
    }

    &>div {
        @include flex(row, flex-start, center, nowrap);
    }

    .date {
        grid-area: date;

        @media (max-width: $bp-xxl) {
            @include flex(column, center, flex-start, nowrap);
        }

        @media (max-width: $bp-xl) {
            align-items: flex-end;
        }
    }

    .id {
        grid-area: id;
        display: flex;
        align-items: center;
    }

    a {
        display: flex;
        align-items: center;
    }

    .descr {
        overflow-wrap: anywhere;
        padding-right: 1em;
        @include clamp(3, 1.4em, false);
    }

    .seller {
        grid-area: seller;
        @include flex(row, flex-start);

        img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 50%;
            margin-right: 0.75em;

            @media (max-width: $bp-xl) {
                width: 40px;
                height: 40px;
            }
        }
    }

    .status {
        grid-area: status;
    }

    .price {
        grid-area: price;
        display: flex;
        align-items: center;

        @media (max-width: $bp-xl) {
            justify-content: flex-end;
        }
    }
}

.feedback-line {
    position: relative;
    @include flex(row, flex-start, flex-start, nowrap);

    .photo {
        position: relative;
        z-index: 2;
        width: 60px;
        height: 60px;
        border-radius: 100%;

        background: var(--glass);



        img {
            position: absolute;
            top: 5px;
            left: 5px;
            --w: 50px;
            @include rounedImg(var(--w));
            object-fit: cover;
        }

    }


    &-main {
        position: relative;
        z-index: 1;
        flex: 1;
        margin-left: -40px;
    }

    &-top {
        @include flex(row, space-between, center, nowrap);
        padding: 0px 1em 0px 60px;
        margin-bottom: 0.5em;

        @media (max-width: $bp-sm) {
            @include flex(column, center, flex-start, nowrap);
            padding: 0px 1em 0px 45px;
        }
    }

    &-text {
        background-color: var(--letter);
        @include lightShadow();
        border-radius: $radius;
        padding: 1em 1em 1.3em 60px;
        font-weight: 300;
        cursor: pointer;

        p {
            @include clamp(3, 1.3em, false);
            overflow-wrap: anywhere;
        }

        .clamp-off {
            @include clamp(910, 1.3em, false);
        }

        @media (max-width: $bp-sm) {
            padding: 1em 1em 1.3em 45px;
        }
    }
}

.purchase-line {
    @include flex(row, space-between, center, nowrap);
    gap: 2em;
    color: var(--total-black);
    background-color: var(--letter);
    @include lightShadow();
    border-radius: $radius;
    padding: 0.6em 1em;

    @media (max-width: $bp-xl) {
        @include flex(column, space-between, flex-start, nowrap);
        gap: 1em;
        padding: 1em 1.5em;
    }

    &-text {
        flex: 1;
    }

    &-user {
        @include flex(row, flex-start, center, nowrap);

        img {
            @include rounedImg(50px);
        }

        h6 {
            font-size: 1.067em;
            font-weight: 500;
            text-align: left;
        }

        button {
            display: flex;
            transition: $transition;

            &:hover,
            &:focus {
                transform: scale(1.3);
            }
        }

        @media (max-width: $bp-xl) {
            width: 100%;

            div {
                flex: 1;
            }
        }
    }
}

.operation-line {
    padding: 1em;
    color: var(--total-black);
    display: grid;
    grid-template-columns: 21% 21% 21% 21% 16%;
    grid-template-rows: 1fr;
    grid-template-areas: "date id type stat sum";
    border-bottom: 1px solid var(--extrapale-blue);

    @media (max-width: $bp-xl) {
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas: "date date date stat stat"
            "id type type sum sum";
        gap: 0.5em;
        font-size: 0.9em;
    }

    @media (max-width: $bp-md) {
        gap: 1em 1em;
        font-size: 1em;
    }

    .date {
        grid-area: date;
    }

    .id {
        grid-area: id;

        @media (max-width: $bp-xl) {
            font-size: 0.9em;
            color: var(--gray);
        }
    }

    .type {
        grid-area: type;
    }

    .stat {
        grid-area: stat;

        @media (max-width: $bp-xl) {
            text-align: right;
        }
    }

    .sum {
        grid-area: sum;
        font-weight: 700;

        @media (max-width: $bp-xl) {
            text-align: right;
        }
    }
}

.appeal-line {
    background-color: var(--letter);
    border-radius: $radius;
    @include lightShadow();
    padding: 1em;
    color: var(--total-black);
    display: grid;
    grid-template-columns: 35% 13% 22% 20% 10%;
    grid-template-rows: 1fr;
    grid-template-areas: "subject id status time btns";

    @media (max-width: $bp-xl) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-template-areas: "date id"
            "subject subject"
            "status btns";
        gap: 0.5em;
    }

    .subject {
        grid-area: subject;
    }

    .id {
        grid-area: id;

        @media (max-width: $bp-xl) {
            text-align: right;
        }
    }

    .status {
        grid-area: status;
    }

    time {
        grid-area: time;
    }

    .btns {
        grid-area: btns;
        @include flex(row, flex-end, center, nowrap);
    }
}

.dialog-preview {
    @include flex(row, flex-start, center, nowrap);
    padding: 0.75em 1.3em;
    position: relative;
    width: 100%;

    &.active {
        background-color: var(--extrapale-gray);
    }

    img {
        @include rounedImg(3em);
        margin-right: 0.5em;
        position: relative;
        z-index: 1;
    }

    .indicator {
        position: absolute;
        bottom: 0.5em;
        left: 3.5em;
        width: 0.67em;
        height: 0.67em;
        border-radius: 50%;
        z-index: 10;

        &.green {
            background-color: var(--green);
        }
    }

    .count {
        display: flex;
        background-color: #66c6e4 !important;
        align-items: center;
        justify-content: center;
        width: 10px;
        height: 10px;
        border-radius: 50%;

    }

    .text {
        flex: 1;
        width: 80%;

        h6 {
            @include clamp(1, 1.2em, true);
        }

        time {
            font-weight: 300;
            color: var(--gray);
            font-size: 0.7em;
        }

        p {
            // @include clamp(1, 1.2em, true);
            display: block;
            font-weight: 300;
            color: var(--gray);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 90%;
            // margin-top: .5em;
        }


    }
}

/***********
ACCOUNT END
***********/
.privacy-policy {

    h4,
    p {
        margin-bottom: 1em;
    }

    h4:not(:first-child) {
        margin-top: 2em;
    }
}

.rules {
    hr {
        border: none;
        opacity: 1;
        height: 1px;
        background-color: var(--extrapale-gray);
        margin: 0.6em 0 1.2em;
    }

    .rules-wrapping {
        background: var(--glass);
        box-shadow: var(--shadow);
        backdrop-filter: blur(5px);
        border-radius: $radius;
        --padX: 2em;
        --padY: 1em;

        @media (max-width: 575px) {
            --padX: 1em;
        }

        &-top {
            background-color: var(--extrapale-gray);
            border-radius: $radius $radius 0px 0px;
            padding: var(--padY) var(--padX);

            ul {
                @include list();
                @include flex();
                padding: 0.25em 1em;
                font-weight: 700;
                font-size: 1.2em;

                &.line-2 {
                    .descr {
                        flex: 1;
                    }

                    .price {
                        width: 100px;
                        text-align: right;
                    }

                    .btns {
                        width: 60px;
                    }
                }

                &.line-3 {
                    display: grid;
                    grid-template-columns: 140px 110px 1fr 200px 130px 80px;
                    grid-template-rows: 1fr;
                    grid-template-areas: "date id descr seller status price";

                    @media (max-width: $bp-xxl) {
                        grid-template-columns: 83px 90px 1fr 200px 130px 80px;
                        font-size: 1em;
                    }

                    .date {
                        grid-area: date;
                    }

                    .id {
                        grid-area: id;
                    }

                    .descr {
                        grid-area: descr;
                    }

                    .seller {
                        grid-area: seller;
                    }

                    .status {
                        grid-area: status;
                    }

                    .price {
                        grid-area: price;
                    }
                }

                &.line-operation {
                    display: grid;
                    grid-template-columns: 21% 21% 21% 21% 16%;
                    grid-template-rows: 1fr;
                    grid-template-areas: "date id type stat sum";

                    .date {
                        grid-area: date;
                    }

                    .id {
                        grid-area: id;
                    }

                    .type {
                        grid-area: type;
                    }

                    .stat {
                        grid-area: stat;
                    }

                    .sum {
                        grid-area: sum;
                    }
                }

                &.line-appeal {
                    display: grid;
                    grid-template-columns: 35% 13% 22% 20% 10%;
                    grid-template-rows: 1fr;
                    grid-template-areas: "subject id status date btns";

                    .subject {
                        grid-area: subject;
                    }

                    .id {
                        grid-area: id;
                    }

                    .status {
                        grid-area: status;
                    }

                    .date {
                        grid-area: date;
                    }

                    .btns {
                        grid-area: btns;
                    }
                }
            }
        }

        &-main {
            padding: var(--padY) var(--padX);

            ul {
                @include list();

                li {
                    border-bottom: 1px solid rgba($color: var(--pale-blue), $alpha: 0.2);

                    @media (max-width: $bp-xl) {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}

.faq {
    .box {
        background-color: var(--total-black);
        color: var(--white);
        border-radius: $radius;
        position: relative;
        padding: 3em 40% 3em 2em;

        img {
            position: absolute;
            bottom: 0px;
            right: 5%;
            width: 35%;
            height: 110%;
            object-fit: scale-down;
            object-position: center bottom;
            z-index: 0;
        }
    }

    ul {
        @include list();

        li {
            border-bottom: 1px solid rgba($color: var(--pale-blue), $alpha: 0.35);
            padding: 0.5em 0px;
            margin-bottom: 1em;

            a {
                color: var(--blue);
                font-weight: 600;
                transition: $transition;

                &:hover,
                &:focus,
                &:active {
                    color: var(--dark-blue);
                }
            }
        }
    }
}

/* Footer */
footer {
    height: var(--f-height);
    background-color: var(--footer-bg);
    color: $white;

    @media (max-width: $bp-lg) {
        background-color: transparent;
        color: var(--black);
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        z-index: 10000;
    }

    .dev-link {
        width: fit-content;
        @include flex(row, center, center, nowrap);
        font-size: 16px;

        svg {
            margin-left: 0.3em;
            font-size: 1.2em;
        }
    }

    .nav-mobile {
        @include flex();

        .logo {
            padding: 0px;

            img {
                width: 70px;
            }
        }

        ul {
            @include list();
            @include flex(row, space-evenly);
            flex: 1;
            background: var(--glass);
            box-shadow: var(--shadowMobile);
            backdrop-filter: blur(2.5px);
            margin-left: 1.7em;
            padding: 0.75em 1.5em;
            border-radius: $radius;

            @media (max-width: $bp-md) {
                justify-content: space-around;
            }

            @media (max-width: $bp-sm) {
                justify-content: space-between;
            }

            li {

                a,
                button {
                    @include flex();
                    font-size: 1.85em;
                    color: var(--gray);
                    transition: $transition;

                    svg {
                        path {
                            stroke-width: 1px;
                        }
                    }

                    &.active {
                        color: var(--blue);

                        svg {
                            path {
                                stroke-width: 1px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Modal */
.modal {


    &-img {
        max-width: 100%;
        width: auto;
        max-height: 80vh;
        border-radius: 4%;
        // object-fit: contain;
    }

    &-content {
        background: var(--label);
        box-shadow: var(--shadow);
        backdrop-filter: blur(5px);
        border: none;
        border-radius: $radius;

        .labeled-input {
            textarea {
                background: var(--label);
            }

            label {
                background: var(--label);

            }
        }
    }

    &-header {
        position: relative;
        height: 35px;
        padding: 0px;
        background-color: var(--extrapale-gray);
        border: none;

        .warning {
            position: absolute;
            right: 2em;
            bottom: 0.25em;

            @media (max-width: $bp-lg) {
                width: 20%;
            }
        }

        .btn-close {
            background: url(imgs/close.svg) no-repeat center;
            background-size: contain;
            background-color: var(--white);
            width: 1.34em;
            height: 1.34em;
            padding: 0px;
            margin: 0px;
            border-radius: 50%;
            overflow: hidden;
            @include safariFix();
            transition: $transition;
            position: absolute;
            top: 0.5em;
            right: 0.5em;
            z-index: 1;

            &:hover,
            &:focus {
                background-color: var(--dark-blue);
            }
        }
    }

    &-dialog {
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 80vh;
    }

    &-body {
        padding: 1.34em 2.68em;

        @media (max-width: $bp-sm) {
            padding: 1.34em;
        }
    }
}

.offcanvas {
    background: var(--label);
    color: var(--font-color);

    &-mobile-menu {
        @include list();
        background: var(--label);
        border-radius: $radius;
        padding: 0 1em;
        font-size: 1.2em;
        box-shadow: var(--shadow);
        backdrop-filter: blur(5px);

        li {
            a {
                display: block;
                padding: 0.75em 0;
                color: inherit;
            }

            &:not(:first-child) {
                border-top: 1px solid var(--light-gray);
            }
        }
    }

    &-body {
        position: relative;

        .sec-promo {
            h1 {
                font-size: 2.2em;
            }

            img {
                width: 40%;
                margin-top: -10%;
            }
        }

        .dev-link {
            @include flex();
        }

        .close {
            position: absolute;
            top: 0px;
            right: 0px;
            font-size: 1.5em;
            @include flex();
            color: var(--gray);
            transition: $transition;
            padding: 0.5em;

            &:hover,
            &:focus,
            &:active {
                color: var(--black);
            }
        }
    }

    &-top,
    &-bottom {
        background-color: transparent;
        height: fit-content !important;
        z-index: 1040 !important;

        .offcanvas-body {
            max-height: 100vh;
            overflow: auto;
        }
    }

    &-top {
        .offcanvas-body {
            padding: calc(var(--h-height) + 1em) 1em 2em 1em;
            background: var(--label);
            border-radius: 0px 0px $radius $radius;
        }
    }

    &-bottom {
        .offcanvas-body {
            padding: 2em 1em calc(var(--f-height) + 1em) 1em;
            background: var(--label);
            border-radius: $radius $radius 0px 0px;
        }
    }
}

.notification-container {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999;
    width: 320px;
    top: 65px;
    padding: 0px 15px;
    max-height: calc(100% - 30px);
}

.notification {
    box-sizing: border-box;
    padding: 13px 20px;
    box-shadow: var(--shadow);
    border-radius: 12px;
    color: var(--font-color);
    background: var(--glass);
    cursor: pointer;
    font-size: 0.9em;
    position: relative;
    margin-bottom: 15px;
}

.notification .title {
    font-size: 1em;
    line-height: 1.2em;
    font-weight: bold;
    margin: 0 0 5px 0;
}

.notification:hover,
.notification:focus {
    opacity: 1;
}

.notification-enter {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
    visibility: visible;
    transform: translate3d(0, 0, 0);
    transition: all 0.4s;
}

.notification-exit {
    visibility: visible;
    transform: translate3d(0, 0, 0);
}

.notification-exit.notification-exit-active {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
    transition: all 0.4s;
}

.notification-info {
    color: var(--light-blue);
}

.notification-success {
    color: var(--green);
}

.notification-warning {
    color: #f89406;
}

.notification-error {
    color: #bd362f;
}

.scroll-to-top {
    z-index: 1042;
    position: fixed;
    bottom: 2.7em;
    right: 1em;
    @include btnStyle();
    backdrop-filter: blur(5px);

    &.mini {
        right: 4em;
    }

    &.maxi {
        right: 20em;
    }

    @media (max-width: $bp-lg) {
        right: 1em;
    }


    background: var(--label);
    opacity: 0.6;
    font-size: 1.5em;
    color: var(--gray);
    width: 2em;
    height: 2em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    // box-shadow: var(--shadowMobile);
    transition: color 0.3s ease-in;

    &:hover,
    &:active {
        // background-color: var(--body-bg);
        opacity: 1;
        color: var(--green);
    }
}

.menu-nav {
    margin: 1em;
    margin-right: auto;
    position: sticky;
    top: var(--h-height);
    height: calc(100vh - var(--h-height) - var(--f-height) - 2em);
    bottom: var(--f-height);
    width: 100%;

    &-conteiner {
        max-width: 90px;

        &.full {
            max-width: 277px;
        }
    }

    .scroll {
        border-radius: $radius;
        margin-right: 2em;
        left: 0;
        width: 96px;
        height: fit-content;
        max-height: calc(100vh - var(--h-height) - 100px);
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 0;
            /* Скрываем полосу прокрутки */
        }


        &.full {
            max-height: calc(100vh - var(--h-height) - 200px);
            width: 277px;
        }
    }

    .wrap {
        position: sticky;
        top: 6em;
        margin-right: 2em;
        left: 0;
        width: 76px;
        height: fit-content;

        @media (max-width: $bp-lg) {
            float: right;
        }

        @media (max-width: $bp-sm) {
            padding: 0;
        }

        &.full {
            width: 267px;
        }
    }

    .content {
        margin-top: 1em;
        width: 76px;
        height: fit-content;

        @media (max-width: $bp-lg) {
            float: right;
        }

        @media (max-width: $bp-sm) {
            padding: 0;
        }

        &.full {
            width: 267px;
        }
    }

    &-balance {
        display: flex;
        background-color: var(--arrow);
        color: white;
        padding: 0.5em 1em 0.5em 1em;
        border-radius: $radius;
        justify-content: space-between;
        align-items: center;
        // margin-top: 1em;

        div {
            @include flex();
            color: white;

            span {
                color: white;
                font-family: $title-font;
                font-size: 2em;
                margin-right: 0.34em;
                line-height: 90%;
            }

            svg {
                color: white;
                font-size: 1.5em;
                padding: 0.1em;
                border: 1px solid var(--white);
                border-radius: 50%;
            }
        }
    }

    &-block {
        background: var(--glass);
        @include btnStyle();
        border-radius: $radius;

    }

    &-login {
        background: var(--glass);
        @include btnStyle();
        border-radius: $radius;
        padding: 1em;
        min-height: 76px;
        justify-content: center;

        h6 {
            text-align: left;
        }

        .button {
            height: auto;
            width: 250px;
            margin-top: 1em;
            margin-bottom: 1em;
            color: white !important;
        }

        .icon {
            display: flex;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background-color: var(--login-icon);
            color: var(--login-key);
            justify-content: center;
            position: relative;
            align-items: center;
        }
    }

    &-top {
        background: var(--glass);
        @include btnStyle();
        border-radius: $radius;
        padding: .67em;
        text-align: center;

        hr {
            opacity: 1;
            width: 100%;
            height: 1px;
            color: var(--extrapale-gray);
            margin: 1em 0 .5em 0;
        }

        .user {
            display: flex;
            text-align: center;
            // margin-right: 1em;
            min-height: 66px;


            img {
                position: relative;
                z-index: 2;
                width: 70px;
                height: 70px;
                border-radius: 100%;

                box-sizing: content-box;

            }

            .img-small {
                width: 48px;
                height: 48px;
            }

            .rating {
                font-size: 1.2em;
                font-weight: 700;
                margin-left: .5em;
                @include flex();


                svg {
                    color: var(--blue);
                    font-size: 1.1em;

                    path {
                        fill: var(--blue);
                    }
                }
            }

            .nickname {
                justify-content: center;
                align-items: center;
                margin-left: .7em;
                font-family: "Bebas";
                font-size: 1.4em;
                line-height: 100%;
                @include clamp(1.1, 1em, true);
                max-height: 2em;
            }

        }

        .info {
            padding-top: .5em;
            padding-left: 1em;
            padding-right: 1em;
            display: flex;
            justify-content: space-between;

            a {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            span {

                font-family: "Bebas";
                font-size: 1.4em;
                font-weight: 400;
            }

        }
    }


    ul {
        @include list();
        margin-bottom: 1em;

        // padding-top: .5em;

        li {
            padding-top: 1em;
            padding-left: 1em;
            padding-right: 1em;

            @media (max-width: $bp-lg) {
                margin-top: 0.5em;
            }

            &:last-child {
                padding-bottom: 1em;

                @media (max-width: $bp-lg) {
                    margin-bottom: 0.5em;
                }

            }

            .indicator {
                position: absolute;
                top: 0em;
                right: 0.3em;
                width: 0.67em;
                height: 0.67em;
                border-radius: 50%;
                z-index: 10;

                &.blue {
                    background-color: var(--blue);
                }
            }


            .dev-link {
                color: var(--blue);
                font-size: .9em;
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;

                .note {
                    width: 4px;
                    height: 4px;
                    display: inline-block;
                    border-radius: 50%;
                    background-color: var(--blue);
                    margin: 0 1em;

                }

                a {
                    padding: 0;
                    color: var(--blue)
                }

                svg {
                    margin-left: .5em;
                    font-size: 1.2em;
                }

            }


            a {
                color: var(--menu);
                @include flex(row, flex-start, center, nowrap);
                padding: 0.67em 1em;
                border-radius: $radius;

                @media (max-width: $bp-lg) {
                    font-size: 1.2em;
                }


                svg {
                    font-size: 1.6em;
                }

                div {
                    margin-left: 0.5em;
                    position: relative;
                    display: flex;
                    align-items: center;

                }

                .badge {
                    background-color: var(--menu-active);
                    padding: 0.67em;
                    border-radius: 10px;
                    color: var(--badge);
                }

                &:hover,
                &:focus {
                    background-color: var(--menu-hover);
                    color: var(--menu-text);
                }

                &.active {
                    background-color: var(--menu-active);
                    color: var(--menu-text);
                }
            }
        }
    }

    #cut {

        transform: translateY(-50%) translateX(-50%);
        z-index: 1000;
        justify-content: left;
        align-items: center;
        padding: 0px;
        position: absolute;
        right: -1.4em;
        top: 2em;
        width: 40px;
        height: 30px;
        filter: drop-shadow(0px 0px 3px rgba(70, 67, 118, 0.2));
        background: var(--menu-substract) no-repeat center bottom;
        transform: rotate(90deg);
        background-size: 100% 50%;
        @include flex();
        border-radius: 50%;

        @media (max-width: $bp-lg) {
            right: unset;
            left: -0.5em;
        }

        .img {
            width: 20px;
            height: 20px;
            left: -1em;
            transition: $transition;
            transform: rotate(-90deg);
            cursor: pointer;

            color: var(--arrow);


            @media (max-width: $bp-lg) {
                transform: rotate(-90deg);
            }
        }

        &.opened {

            .img {
                transform: rotate(90deg);
                left: -1em;

                @media (max-width: $bp-lg) {
                    transform: rotate(90deg);
                }
            }
        }
    }

}

.panel-open {
    max-width: calc(100vw - var(--l-menu-width) - 70px);
    // z-index: 1000;
}

.panel-closed {
    max-width: calc(100vw - var(--s-menu-width) - 70px);
    // z-index: 1;
}

.menu-chat {
    margin-right: auto;
    z-index: 1042;
    position: fixed;
    bottom: 0.4em;
    right: 1em;
    width: 52px;
    height: auto;

    &-conteiner {
        max-width: 60px;
    }


    &-block {
        background: var(--label);
        @include btnStyle();
        border-radius: $radius;

    }



    ul {
        @include list();
        // padding-top: .5em;

        li {
            .general-chat {
                @include flex(row, center, center, nowrap);
                padding: 0.4em 0em;

                .count {
                    background-color: var(--black);
                    color: var(--white);
                    @include rounedImg(4em);
                    @include flex(column, center, center, nowrap);
                    font-size: 10px;
                    text-align: center;
                    line-height: 100%;
                }

                h6 {
                    margin-left: 0.5em;
                    flex: 1;
                    text-align: left;
                }
            }

            .preview {
                @include flex(row, center, center, nowrap);
                padding: 0.4em 0em;
                position: relative;
                width: 100%;
                cursor: pointer;

                &:hover,
                &:focus {
                    color: var(--blue);
                }

                &.active {
                    background-color: var(--menu-active);
                    color: var(--menu-text);
                }

                &.active {
                    background-color: var(--extrapale-gray);
                }

                img {
                    @include rounedImg(2.67em);

                    position: relative;
                    z-index: 1;
                }

                .indicator {
                    position: absolute;
                    bottom: 0.5em;
                    right: 0.5em;
                    width: 0.67em;
                    height: 0.67em;
                    border-radius: 50%;
                    z-index: 10;

                    &.green {
                        background-color: var(--green);
                    }
                }

                .count {
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 10;
                    opacity: 0.9;

                    span {
                        display: flex;
                        background-color: #66c6e4 !important;
                        align-items: center;
                        justify-content: center;
                        color: #0d59ab;
                        font-size: 12px;
                        font-weight: 400;
                        padding: 0.2em 0.6em;
                        border-radius: 10px;

                        @media (max-width: 767px) {
                            font-size: 13px;
                        }
                    }
                }


                .text {
                    flex: 1;
                    width: 80%;

                    h6 {
                        @include clamp(1, 1.2em, true);
                    }

                    time {
                        font-weight: 300;
                        color: var(--gray);
                        font-size: 0.7em;
                    }

                    p {
                        // @include clamp(1, 1.2em, true);
                        display: block;
                        font-weight: 300;
                        color: var(--gray);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 90%;
                        margin-top: .5em;
                    }
                }
            }

            hr {
                margin: 0.3em 0.67em;
            }

            a {
                color: var(--menu);
                @include flex(row, center, center, nowrap);
                padding: 0.67em 1em;
                border-radius: $radius;

                @media (max-width: $bp-lg) {
                    font-size: 1.2em;
                }

                .count {
                    position: absolute;
                    top: 1em;
                    right: 1em;
                    z-index: 10;
                    opacity: 0.9;
                }

                svg {
                    font-size: 2em;
                }

                img {
                    width: 58px;
                    height: 58px;
                    margin: 0;
                }



                &:hover,
                &:focus {
                    color: var(--blue);
                }

                &.active {
                    background-color: var(--menu-active);
                    color: var(--menu-text);
                }
            }
        }
    }

    #cut {

        transform: translateY(-50%) translateX(-50%);
        z-index: 1000;
        padding: 0px;
        position: absolute;
        background: var(--blue);
        right: 0;
        top: -1.5em;
        width: 22px;
        height: 22px;
        transform: rotate(90deg);
        background-size: 100% 50%;
        @include flex();
        border-radius: 50%;
        z-index: 10;
        border: 1px solid;

        border-image-source: linear-gradient(111.82deg, rgba(255, 255, 255, 0.7) 0.48%, rgba(255, 255, 255, 0) 23.46%);



        @media (max-width: $bp-lg) {
            right: unset;
            left: -0.5em;
        }

        img {


            width: 20px;
            height: 20px;
            left: -1em;
            transition: $transition;

            transform: rotate(-90deg);


            @media (max-width: $bp-lg) {
                transform: rotate(-90deg);
            }

        }

        &.opened {

            img {
                transform: rotate(90deg);
                left: -1em;

                @media (max-width: $bp-lg) {
                    transform: rotate(90deg);
                }
            }
        }


    }

}

.full-menu-chat {
    margin-right: auto;
    z-index: 1042;
    position: fixed;
    bottom: 0.4em;
    right: 4em;




    &-block {
        background: var(--label);
        border-radius: $radius;
        width: 350px;
        height: 460px;
        position: sticky;
        top: 6em;
        margin-right: 2em;
        left: 0;
        @include btnStyle();



    }


    .top {
        display: flex;
        width: 100%;
        @include btnStyle();
        margin-bottom: 0.8em;



        form {
            width: 90%;

            input {
                padding: .6em 1em;
                padding-right: 2.4em;
                background-color: transparent;
            }
        }

        button {
            img {
                transform: translateY(-50%) translateX(-50%);
                z-index: 1000;
                padding: 0px;
                position: absolute;
                right: 0;
                top: 1.5em;
                width: 22px;
                height: 22px;
            }

        }
    }

    .chat {
        &-form {
            textarea {
                font-size: 0.9em;
            }
        }

        &-window {
            height: calc(379px - var(--textarea-height));

            &.system {
                height: 387px !important;
            }

        }

        width: 100%;
    }

    ul {
        @include list();
        // padding-top: .5em;
        position: sticky;
        width: 100%;
        max-height: 370px;
        overflow-y: auto;

        div {
            max-height: 370px;
        }

        li {
            .general-chat {
                @include flex(row, center, center, nowrap);
                padding: 0.67em 1em;

                .count {
                    background-color: var(--black);
                    color: var(--white);
                    @include rounedImg(4em);
                    @include flex(column, center, center, nowrap);
                    font-size: 10px;
                    text-align: center;
                    line-height: 100%;
                }

                h6 {
                    margin-left: 0.5em;
                    flex: 1;
                    text-align: left;
                }
            }

            .preview {
                @include flex(row, center, center, nowrap);
                padding: 0.67em 1em;
                position: relative;
                width: 100%;

                &:hover,
                &:focus {
                    background-color: var(--menu-active);
                }

                &.active {
                    background-color: var(--menu-active);
                    color: var(--menu-text);
                }

                img {
                    @include rounedImg(2.67em);

                    position: relative;
                    z-index: 1;
                }

                .count {
                    display: flex;
                    background-color: #66c6e4 !important;
                    align-items: center;
                    justify-content: center;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;

                }

                .indicator {
                    position: absolute;
                    bottom: 0.5em;
                    left: 3em;
                    width: 0.67em;
                    height: 0.67em;
                    border-radius: 50%;
                    z-index: 10;

                    &.green {
                        background-color: var(--green);
                    }
                }


                .text {
                    flex: 1;
                    width: 80%;

                    h6 {
                        @include clamp(1, 1.2em, true);
                    }

                    time {
                        font-weight: 300;
                        color: var(--gray);
                        font-size: 0.7em;
                    }

                    p {
                        // @include clamp(1, 1.2em, true);
                        display: block;
                        font-weight: 300;
                        color: var(--gray);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 90%;
                    }
                }
            }



            a {
                color: var(--menu);
                @include flex(row, center, center, nowrap);
                padding: 0.67em 1em;
                border-radius: $radius;

                @media (max-width: $bp-lg) {
                    font-size: 1.2em;
                }

                .count {
                    position: absolute;
                    top: 1em;
                    right: 1em;
                    z-index: 10;
                    opacity: 0.9;
                }

                svg {
                    font-size: 2em;
                }

                img {
                    width: 58px;
                    height: 58px;
                    margin: 0;
                }




            }
        }
    }



}

.pointer {
    cursor: pointer;
}

.notOffer {
    display: flex;
    align-items: flex-start;

    img {
        width: 139px;
        height: 139px;
        margin-right: 2em;
        margin-left: 2em;
    }

    h4 {
        margin-top: 1em;
        margin-bottom: 1em;
    }
}

.ban {
    color: $rose;
    font-size: 1.5em;
}